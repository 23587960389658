import {
  Box, Button,
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import * as yup from 'yup';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import UserService from '../../services/UserService';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import AlertUtils from '../../utils/AlertUtil';
import PersonalizedLink from './components/PersonalizedLink';
import translator from '../../theme/translator.json';
import Utils from '../../utils/Utils';

const PersonalizedLinks: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  return (
    <Box sx={{ my: 3 }}>
      {user && user.personalizedLinks
        && <Formik
          initialValues={{ links: user.personalizedLinks || [] }}
          validationSchema={yup.object().shape({
            links: yup.array()
              .of(
                yup.object().shape({
                  title: yup
                    .string().max(200).required(Utils.getTranslation(language, translator.formMessages.requiredField)),
                  url: yup
                    .string().url(Utils.getTranslation(language, translator.formMessages.invalidLink)),
                }),
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            try {
              UserService.update(user.id, {
                personalizedLinks: values.links,
              });
              AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.linkCreated), dispatch);
              setSubmitting(false);
            } catch (e) {
              AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
              setSubmitting(false);
            }
          }}
        >{({ values }) => (
            <Form>
              <FieldArray
                name="links"
                render={(arrayHelpers) => (
                  <div>
                    <Box sx={{
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                    }}>
                      {values.links && values.links.length > 0 && (
                        values.links.map((link : {title:string, url:string}, i:number) => (
                          <PersonalizedLink key={i} link={link} index={i} arrayHelpers={arrayHelpers} />
                        ))
                      )}
                    </Box>
                    <Box sx={{ width: '100%', textAlign: 'center', py: 2 }}>
                      <Button onClick={() => arrayHelpers.push({
                        title: '',
                        url: '',
                      })} sx={{
                        borderRadius: '4px',
                        minWidth: '30%',
                      }} variant="contained" color="primary">{Utils.getTranslation(language, translator.formLabels.addLink)}</Button>
                    </Box>
                  </div>
                )}
              />
            </Form>
          )}
        </Formik>}
    </Box>
  );
};

export default PersonalizedLinks;
