import { Close } from '@mui/icons-material';
import {
  Box, Button, IconButton, Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import ClientService from '../services/ClientService';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import AlertUtils from '../utils/AlertUtil';
import Spinner from './Spinner';
import SuiviDateInput from './SuiviDateInput';
import Utils from '../utils/Utils';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';

const validationSchema = yup.object({
  date: yup.string().required('Ce champ est requis'),
});

const initialValues = {
  date: '',
  customDate: dayjs().unix() * 1000,
};

type Props = {
    onClose: () => void,
    clientId: string,
};

const MoveSuiviPopoverContent: React.FC<Props> = ({
  onClose,
  clientId,
}) => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [loading, setLoading] = useState(false);

  return (
    <>
      {
        user
        && <Box sx={{
          maxWidth: 500,
          textAlign: 'center',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2 }}>
            <Typography sx={{ flexGrow: 1, fontSize: 18, fontWeight: 600 }}>{Utils.getTranslation(language, translator.components.moveSuiviPopup.moveSuivi)}</Typography>
            <IconButton aria-label="move"
              color="primary"
              onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                setLoading(true);
                const newSuiviDate = (values.date === 'custom')
                  ? Timestamp.fromDate(new Date(values.customDate)) : Timestamp.fromDate(new Date(values.date));
                await ClientService.updateNextSuiviDate(user.id, clientId, newSuiviDate);
                resetForm();
                onClose();
                setLoading(false);
                AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.suiviDateModified), dispatch);
              } catch (e) {
                setLoading(false);
                console.error(e);
                AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
              }
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <SuiviDateInput showNull />
                <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }} disabled={formik.isSubmitting}>
                  {Utils.getTranslation(language, translator.general.deplacer)}
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      }
      <Spinner show={loading}/>
    </>

  );
};

export default MoveSuiviPopoverContent;
