import { ArrowBack, ArrowForward, CloseOutlined } from '@mui/icons-material';
import {
  AppBar, Box, IconButton, Toolbar, Tooltip, Typography,
} from '@mui/material';
import ClientType from '../../../models/ClientType';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import { useAppSelector } from '../../../store/Hooks';
import Utils from '../../../utils/Utils';
import translator from '../../../theme/translator.json';

type Props = {
    client: ClientType
    clientIndex: number,
    onClose: () => any
    onNextClient: () => any
    onPreviousClient: () => any
    orderedClientsList: ClientType[]
};

const Header: React.FC<Props> = ({
  client, onClose, onNextClient, onPreviousClient, orderedClientsList, clientIndex,
}) => {
  const { navDisabled } = useAppSelector((state) => state.client);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  return (
    <AppBar sx={{
      position: 'sticky', top: 0, left: 0, right: 0,
    }}>
      <Toolbar sx={{ '@media (max-width:565px)': { display: 'block', py: 1 } }}>
        <Typography sx={{ flex: 1, '@media (max-width:565px)': { fontSize: 15 } }} variant="h6" component="div">
          {Utils.getTranslation(language, translator.components.ficheClient.title)}
          <strong>
            {
              client
            && ` ${client.name}`
            }
          </strong>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Box
            sx={{
              mr: '10px',
            }}
          >
            { !navDisabled && <>
              <Tooltip title={Utils.getTranslation(language, translator.general.precedent)}>
                <span>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onPreviousClient}
                    aria-label="precedent"
                    disabled={clientIndex === 0}
                  >
                    <ArrowBack />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={Utils.getTranslation(language, translator.general.suivant)}>
                <span>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onNextClient}
                    aria-label="suivant"
                    disabled={clientIndex === orderedClientsList.length - 1}
                  >
                    <ArrowForward />
                  </IconButton>
                </span>
              </Tooltip></>}
          </Box>
          <Tooltip title={Utils.getTranslation(language, translator.general.close)}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
          </Tooltip>      </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
