import '../../theme/css/global.css';
import './Layout.css';
import {
  Box, Container, styled,
} from '@mui/material';
import { useState } from 'react';
import {
  CalendarMonth,
  FormatListBulleted,
  Groups3, Person, Snooze, Star,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import AlertsContainer from '../AlertsContainer';
import MenuItem from '../../models/MenuItem';
import Sidebar from './components/Sidebar';
import ClientsSummary from '../clientsSummary/ClientsSummary';
import { useAppSelector } from '../../store/Hooks';
import PricingPlan from '../../models/enums/PricingPlans';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 62,
}));

type Props = {
  children?: React.ReactNode
  title: string
};

const Layout: React.FC<Props> = ({ children, title }) => {
  const sidebarMenuItems = [
    {
      href: '/',
      icon: (<CalendarMonth color="primary" fontSize="small" />),
      title: {
        en: 'Follow-ups',
        fr: 'Suivis',
        es: 'Seguimientos',
        pt: 'Acompanhamentos',
      },
    },
    {
      href: '/clients/listes/peu-interessant',
      icon: (<Snooze color="primary" fontSize="small" />),
      title: {
        en: 'Contacts on hold',
        fr: 'Contacts en attente',
        es: 'Contactos en espera',
        pt: 'Contatos em espera',
      },
    },
    {
      href: '/clients/listes/reserve',
      icon: (<FormatListBulleted color="primary" fontSize="small" />),
      title: {
        en: 'Bank of contacts',
        fr: 'Banque de contacts',
        es: 'Banco de contactos',
        pt: 'Banco de contatos',
      },
    },
    {
      href: '/clients/',
      icon: (<Person color="primary" fontSize="small" />),
      title: {
        en: 'Clients',
        fr: 'Clients',
        es: 'Clientes',
        pt: 'Clientes',
      },
    },
    {
      href: '/clients/prospect',
      icon: (<Groups3 color="primary" fontSize="small" />),
      title: {
        en: 'Prospects',
        fr: 'Prospects',
        es: 'Prospectos',
        pt: 'Potenciais clientes',
      },
    },
    {
      href: '/clients/proposition',
      icon: (<Star color="primary" fontSize="small" />),
      title: {
        en: 'Proposals',
        fr: 'Propositions en cours',
        es: 'Propuestas en curso',
        pt: 'Propostas em andamento',
      },
    },
  ] as MenuItem[];

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const { user, language } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <>
      <LayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Box>
        <AlertsContainer/>
        <ClientsSummary />
      </LayoutRoot>
      <Navbar title={title} onSidebarOpen={() => setSidebarOpen(true)} />
      <Sidebar
        menuItems={sidebarMenuItems}
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
      {user && user.plan === PricingPlan.FREEMIUM && <Box sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#f55c47',
        padding: '4px 0',
        cursor: 'pointer',
      }}
      onClick={() => navigate('/pricing')}>
        <Container sx={{ textAlign: 'center', fontSize: '14px', color: 'white' }}>
          {Utils.getTranslation(language, translator.pages.upgrade.upgrade)}
        </Container>
      </Box>}
    </>
  );
};

export default Layout;
