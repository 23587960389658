import {
  Check,
  Close, Delete, Edit, Launch,
} from '@mui/icons-material';
import {
  Box, IconButton, Link, TextField, Typography,
} from '@mui/material';
import { Field, getIn, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import PersonalizedLinkType from '../../../models/PersonalizedLinkType';
import Utils from '../../../utils/Utils';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import translator from '../../../theme/translator.json';
import { useAppSelector } from '../../../store/Hooks';

const ErrorMessage: React.FC<{n:string}> = ({ n }) => (
  <Field
    name={n}
  >{({ form } : {form:any}) => {
      const error = getIn(form.errors, n);
      const touch = getIn(form.touched, n);
      return (
        <Typography color="error" sx={{ fontSize: 12 }}>{touch && error ? error : null}</Typography>
      );
    }}
  </Field>
);

// eslint-disable-next-line arrow-body-style
const PersonalizedLink: React.FC<{link:PersonalizedLinkType, index:number, arrayHelpers: any}> = ({ link, index, arrayHelpers }) => {
  const [isEditing, setIsEditing] = useState(false);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const {
    values, handleChange, handleSubmit, isSubmitting, errors,
  } = useFormikContext<{links:[{title:string, url:string}]}>();

  useEffect(() => {
    if (!link.title) {
      setIsEditing(true);
    }
  }, [link]);

  return (
    <Box sx={{
      background: 'white',
      borderRadius: '8px',
      minWidth: '30%',
      '@media (max-width: 600px)': {
        width: '46%',
      },
      p: 2,
    }}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%',
      }}>
        <Box sx={{
          flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}>
          {(isEditing)
            ? <>
              <TextField
                label={Utils.getTranslation(language, translator.formLabels.linkTitle)}
                variant="standard"
                size='small'
                name={`links[${index}].title`}
                value={values.links[index].title}
                onChange={handleChange}
              />
              <ErrorMessage n={`links[${index}].title`} />
              <TextField
                label={Utils.getTranslation(language, translator.formLabels.link)}
                variant="standard"
                size='small'
                name={`links[${index}].url`}
                sx={{ my: 1 }}
                value={values.links[index].url}
                onChange={handleChange}
              />
              <ErrorMessage n={`links[${index}].url`} />
            </>
            : <Link href={link.url} target="_blank" sx={{
              color: '#000', fontWeight: '600', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 1,
            }}>
              <Launch />
              {link.title}
            </Link>}
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2,
        }}>
          <div>
            {(isEditing) && <IconButton size="small" onClick={() => {
              arrayHelpers.remove(index);
              handleSubmit();
              setIsEditing(false);
            }}>
              <Delete fontSize='small' />
            </IconButton>}
          </div>
          <div>
            {(isEditing) && <IconButton size="small" onClick={() => {
              if (!isSubmitting) {
                handleSubmit();
                if (!(errors.links && errors.links[index])) {
                  setIsEditing(false);
                }
              }
            }}>
              <Check fontSize='small' />
            </IconButton>}
            {(isEditing) && <IconButton size="small" onClick={() => {
              if (!(errors.links && errors.links[index])) {
                setIsEditing(false);
              }
            } }>
              <Close fontSize='small' />
            </IconButton>}
            {!(isEditing) && <IconButton size="small" onClick={() => {
              setIsEditing(true);
            }}>
              <Edit fontSize='small' />
            </IconButton>}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalizedLink;
