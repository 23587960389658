import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';

abstract class Utils {
  /**
   * Convert name to minified version
   * @param {string} name
   * @returns {string} minified name
   */
  static stringAvatar = (name: string) => {
    let child = `${name[0]}${name[1]}${name[2]}`;

    if (name.split(' ').length >= 2 && name.split(' ')[1][0]) {
      child = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
    }

    return child;
  };

  /**
   * Util function that calculate the first day of the week.
   *      - Used for getting the start date of the week
   * @condition
   *      - FirstDayOfWeek start time are sunday's at 4 am
   * @params Date
   * @return Date
  */
  static firstDayOfWeek = (dateObject: Date) => new Promise<Date>((resolve) => {
    try {
      const date = dateObject;
      if (date.getDay() === 0) {
        const dateMin = new Date(date);
        dateMin.setHours(4, 0, 0, 0);
        if (date.getTime() >= dateMin.getTime()) {
          date.setHours(4, 0, 0, 0);
          resolve(date);
        } else {
          date.setDate(date.getDate() - 7);
          date.setHours(4, 0, 0, 0);
          resolve(date);
        }
      }
      date.setDate(date.getDate() - (date.getDay() || 7));
      date.setHours(4, 0, 0, 0);
      resolve(date);
    } catch (e) {
      const error: any = e;
      throw new Error(error.message);
    }
  });

  /**
   * Get the Display value of a field on current language
   *
   * @param {string} language
   * @param {any} field
   * @returns {string} the display value
   */
  static getTranslation = (language: string, field: any, params?: any): string => {
    let lang = language;
    let translation = '';
    if (!lang) lang = SupportedLanguages.DEFAULT;
    translation = field[lang];

    if (params) {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const key in params) {
        if (typeof params[key] === 'string') translation = translation.replace(`{${key}}`, params[key]);
      }
    }

    return translation;
  };

  /**
   * Get the Display value of an enum translated to current language
   *
   * * I know this hurts to watch...but it also hurt to code. I hope in the future
   * * we either stray from this horrible path TypeScript has laid out for us and we
   * * can reminisce the past with a tear in eye, celebrating the progress the human
   * * being has made over the last decades. But anyway, it works, so we should just
   * * use this despicable lump of code... cause it works!
   * *                                                      — the intern, 2022-05-18
   *
   * @param {string} language
   * @param {string} values the enum in camelCase
   * @param {string} value the value in the enum
   *
   * @returns {string | boolean} the translated value or false if value was not found in translator
   */
  static getTranslatedEnumValue = (language: string, values: string, value: string): string | boolean => {
    // Get all the enums
    const enums = Object.entries(translator.models.enums);

    // Find the corresponding enum object
    let enumObject: any[] = [];
    enums.forEach((enumObjectTemp: any[]) => {
      if (enumObjectTemp[0] === values) enumObject = Object.entries(enumObjectTemp[1]);
    });
    if (!enumObject.length) return false;

    // Find the corresponding value
    let valueObject;
    enumObject.forEach((valueObjectTemp: any[]) => {
      if (valueObjectTemp[0] === value) valueObject = { ...valueObjectTemp[1] };
    });
    if (!valueObject) return false;

    // Call the translation utility
    return this.getTranslation(language, valueObject);
  };

  /**
   * Creates an array of options from an enum
   *
   * @param {enum} values
   * @returns {Array<{ string, string }>} an array of options
   */
  static optionsFromEnum = (values: any) => {
    const options: any[] = [];
    (Object.keys(values) as (keyof typeof values)[]).map(
      (key, index) => options.push(
        { value: values[key], label: values[key] },
      ),
    );
    return options;
  };

  static generateRelativeDateOptions = (language?: SupportedLanguages) : any => {
    let lang = language;
    if (!lang) lang = SupportedLanguages.DEFAULT;

    return [
      {
        value: dayjs().add(0, 'day').hour(23).minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.aujourdhui),
      },
      {
        value: dayjs().add(1, 'day').hour(23).minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.demain),
      },
      {
        value: dayjs().add(7, 'day').hour(23).minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.nextWeek),
      },
    ];
  };

  static generateMonthlyDateOptions = (language?: SupportedLanguages) : any => {
    let lang = language;
    if (!lang) lang = SupportedLanguages.DEFAULT;

    const currentMonth = dayjs().month();
    return [
      {
        value: dayjs().startOf('month').month((currentMonth >= 0) ? 12 + 0 : 0).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.jan),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 1) ? 12 + 1 : 1).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.fev),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 2) ? 12 + 2 : 2).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.mar),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 3) ? 12 + 3 : 3).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.avr),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 4) ? 12 + 4 : 4).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.mai),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 5) ? 12 + 5 : 5).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.juin),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 6) ? 12 + 6 : 6).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.juil),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 7) ? 12 + 7 : 7).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.aout),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 8) ? 12 + 8 : 8).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.sept),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 9) ? 12 + 9 : 9).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.oct),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 10) ? 12 + 10 : 10).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.nov),
      },
      {
        value: dayjs().startOf('month').month((currentMonth >= 11) ? 12 + 11 : 11).hour(23)
          .minute(0)
          .second(0)
          .millisecond(0)
          .unix() * 1000,
        label: this.getTranslation(lang, translator.dates.dec),
      },
    ];
  };

  static renderSuiviDate = (date: any, language?: SupportedLanguages) : string => {
    let lang = language;
    if (!lang) lang = SupportedLanguages.DEFAULT;

    dayjs.extend(isToday);
    dayjs.extend(isTomorrow);

    if (dayjs(date).isToday()) return this.getTranslation(lang, translator.dates.aujourdhui);
    if (dayjs(date).isTomorrow()) return this.getTranslation(lang, translator.dates.demain);
    return dayjs(date).format('DD/MM/YYYY');
  };
}

export default Utils;
