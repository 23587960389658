import '../../theme/css/global.css';

import {
  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import { updateLanguage } from '../../store/slices/UserSlice';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';
import UserService from '../../services/UserService';
import ErrorService from '../../services/ErrorService';
import { auth } from '../../FirebaseConfig';

const LanguageSelector: React.FC = () => {
  const { language } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    try {
      if (!auth.currentUser) {
        const langParam = searchParams.get('lang');
        if (langParam
          && (langParam === SupportedLanguages.FR || langParam === SupportedLanguages.EN || langParam === SupportedLanguages.ES || langParam === SupportedLanguages.PT)) {
          dispatch(updateLanguage(langParam as SupportedLanguages));
        } else {
          navigate(`${location.pathname}?lang=${SupportedLanguages.DEFAULT}`);
          dispatch(updateLanguage(SupportedLanguages.DEFAULT));
        }
      }
    } catch (e: any) {
      ErrorService.handleError(e, dispatch, language);
    }
  }, [searchParams.get('lang')]);

  const handleChange = async (event: SelectChangeEvent) => {
    try {
      if (auth.currentUser && auth.currentUser.uid) {
        await UserService.update(auth.currentUser.uid, { language: event.target.value });
        dispatch(updateLanguage(event.target.value as string));
      } else {
        navigate(`${location.pathname}?lang=${event.target.value}`);
      }
    } catch (e: any) {
      ErrorService.handleError(e, dispatch, language);
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{Utils.getTranslation(language, translator.components.languageToggle)}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          label={Utils.getTranslation(language, translator.components.languageToggle)}
          onChange={handleChange}
          size="small"
        >
          <MenuItem value={SupportedLanguages.FR}>FR</MenuItem>
          <MenuItem value={SupportedLanguages.EN}>EN</MenuItem>
          <MenuItem value={SupportedLanguages.ES}>ES</MenuItem>
          <MenuItem value={SupportedLanguages.PT}>PT</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default LanguageSelector;
