/* eslint-disable @typescript-eslint/no-empty-function */
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';

import { ThemeProvider, CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  onSnapshot, doc, collection, orderBy, query, where,
} from 'firebase/firestore';
import {
  User as FirebaseUser,
} from 'firebase/auth';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { db, auth } from './FirebaseConfig';
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';
import themeConfig from './theme/ThemeConfig';
import Signup from './pages/auth/Signup';
import NotFound from './pages/NotFound';
import { useAppDispatch, useAppSelector } from './store/Hooks';
import {
  updateClients, updateLanguage, updateSuivis, updateUser,
} from './store/slices/UserSlice';
import User from './models/User';
import Spinner from './components/Spinner';
import PrivateRoutes from './routing/PrivateRoutes';
import ForgotPassword from './pages/auth/ForgotPassword';
import SupportedLanguages from './models/enums/SupportedLanguages';
import AdminRoutes from './routing/AdminRoutes';
import Logs from './pages/admin/Logs';
import Settings from './pages/Settings';
import Notifications from './pages/Notifications';
import ErrorService from './services/ErrorService';
import Clients from './pages/Clients';
import VisibilityState from './models/enums/VisibilityState';
import PurchaseSuccess from './pages/PurchaseSuccess';
import UpgradePage from './pages/Upgrade';
import BoiteOutils from './pages/BoiteOutils';
import SuperAdminPage from './pages/Superadmin';
import PricingPage from './pages/PricingPage';

if (process.env.NODE_ENV === 'production') {
  // Disable react dev tools
  disableReactDevTools();
}

function App() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user) as User;

  const [loading, setLoading] = useState(true);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      let unsubscribe = () => {};
      auth.onAuthStateChanged((response) => {
        const firebaseUser = response as FirebaseUser;
        if (firebaseUser) {
          unsubscribe = onSnapshot(doc(db, 'Users', firebaseUser.uid), (result) => {
            const userDoc = result.data() as User;
            dispatch(updateUser(userDoc));
            dispatch(updateLanguage(userDoc?.language));
          });
        } else {
          dispatch(updateUser(null));
          dispatch(updateLanguage(SupportedLanguages.DEFAULT));
        }
        setLoading(false);
      });
      return unsubscribe;
    } catch (e: any) {
      ErrorService.handleError(e, dispatch, language);
      setLoading(false);
    }
  }, []);

  // bind to clients collections
  useEffect(() => {
    let unsubscribe = () => {};
    if (user && user.id) {
      const clientsQuery = query(
        collection(db, 'Users', user.id, 'Clients'),
        where('visibility', '==', VisibilityState.VISIBLE),
        orderBy('name'),
      );
      unsubscribe = onSnapshot(clientsQuery, (clientsSnapshot) => {
        const clients : any[] = [];
        clientsSnapshot.forEach((clientDoc) => {
          clients.push({
            ...clientDoc.data(),
            id: clientDoc.id,
          });
        });
        dispatch(updateClients(clients));
      });
    }
    return unsubscribe;
  }, [user]);

  // bind to suivis collections
  useEffect(() => {
    let unsubscribe = () => {};
    if (user && user.id) {
      const suivisQuery = query(
        collection(db, 'Users', user.id, 'Suivis'),
        where('visibility', '==', VisibilityState.VISIBLE),
        orderBy('date'),
      );
      unsubscribe = onSnapshot(suivisQuery, (suivisSnapshot) => {
        const suivis : any[] = [];
        suivisSnapshot.forEach((suiviDoc) => {
          suivis.push({
            ...suiviDoc.data(),
            id: suiviDoc.id,
          });
        });
        dispatch(updateSuivis(suivis));
      });
    }
    return unsubscribe;
  }, [user]);

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline/>
      <div className="app" style={{ background: '#007591' }}>
        {
          !loading
          && <BrowserRouter>
            <Routes>
              <Route element={<PrivateRoutes />} >
                <Route path="/" element={<Dashboard />}/>
                <Route path="/clients" element={<Clients />}/>
                <Route path="/clients/:filter/:secondnav?" element={<Clients />}/>
                <Route path="/boite-outils" element={<BoiteOutils />}/>
                <Route path="/settings" element={<Settings />}/>
                <Route path="/notifications" element={<Notifications />}/>
                <Route path="/purchase-success" element={<PurchaseSuccess />}/>
                <Route path="/pricing" element={<PricingPage />}/>
                <Route path="/upgrade" element={<UpgradePage />}/>
                <Route path="/superadmin" element={<SuperAdminPage />}/>
                <Route element={<AdminRoutes />}>
                  <Route path="/logs" element={<Logs />}/>
                </Route>
              </Route>
              <Route path='/signup' element={<Signup />}/>
              <Route path='/forgot-password' element={<ForgotPassword />}/>
              <Route path='/login' element={<Login />}/>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        }
        <Spinner show={loading}></Spinner>
      </div>
    </ThemeProvider>
  );
}

export default App;
