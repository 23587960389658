import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DataGrid, frFR, enUS, GridColDef, GridToolbar,
} from '@mui/x-data-grid';
import {
  Box, Chip, Fade, IconButton, Paper, Popper, Tooltip, Typography,
} from '@mui/material';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { Cancel, DriveFileMove } from '@mui/icons-material';
import Layout from '../components/layout/Layout';
import Spinner from '../components/Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import AlertUtils from '../utils/AlertUtil';
import MoveSuiviPopoverContent from '../components/MoveSuiviPopoverContent';
import MoveSuiviPopup from '../components/moveSuiviPopup/MoveSuiviPopup';
import ClientType from '../models/ClientType';
import ClientService from '../services/ClientService';
import {
  setClientOpenId, setClientPopupOpen, setNavDisabled, setOrderedClientsList,
} from '../store/slices/ClientSlice';

const Dashboard: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const { clients, user } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]) as any[];
  const [moveSuiviPopoverAnchorEl, setMoveSuiviPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [moveSuiviPopoverOpen, setMoveSuiviPopoverOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [addSuiviDialogOpen, setAddSuiviDialogOpen] = useState(false);

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  const handleSelectSuivi = (id: string, event: any) => {
    setSelectedClientId(id);
    setMoveSuiviPopoverAnchorEl(event.currentTarget);
    setMoveSuiviPopoverOpen((prev) => moveSuiviPopoverAnchorEl !== event.currentTarget || !prev);
  };

  const handleSelectSuiviMobile = (id: string, event: any) => {
    setSelectedClientId(id);
    setAddSuiviDialogOpen(true);
  };

  const closeSuivi = async (suiviId: string) => {
    try {
      setLoading(true);
      await ClientService.updateNextSuiviDate(user.id, suiviId, null);

      setLoading(false);
      AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.suiviDeleted), dispatch);
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'nextSuivi',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.dateSuivi),
      width: 250,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
        }}>
          <Typography sx={{
            color: (dayjs(params.row.nextSuivi.seconds * 1000) < dayjs().hour(0)) ? 'error.main' : 'black',
            fontSize: '1.1em',
            fontWeight: 600,
          }}>
            {Utils.renderSuiviDate(params.row.nextSuivi.seconds * 1000, language)}
          </Typography>
          <Box sx={{
            display: 'flex',
          }}>
            <Tooltip title={Utils.getTranslation(language, translator.components.moveSuiviPopup.moveSuivi)}>
              <IconButton
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                }}
                aria-label="move"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleSelectSuivi(params.row.id, event);
                }}>
                <DriveFileMove />
              </IconButton>
            </Tooltip>
            <Tooltip title={Utils.getTranslation(language, translator.components.moveSuiviPopup.moveSuivi)}>
              <IconButton
                sx={{
                  display: { xs: 'flex', lg: 'none' },
                }}
                aria-label="move"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleSelectSuiviMobile(params.row.id, event);
                }}>
                <DriveFileMove />
              </IconButton>
            </Tooltip>
            <Tooltip title={Utils.getTranslation(language, translator.components.moveSuiviPopup.deleteSuivi)}>
              <IconButton aria-label="delete"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  closeSuivi(params.row.id);
                }}>
                <Cancel />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.nomDuClient),
      width: 200,
    },
    {
      field: 'tags',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.categories),
      width: 150,
      disableExport: true,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'flex-start', width: '100%', overflowX: 'auto', height: '100%',
        }}>
          {
            params.row.tags.map((tag: string, index: number) => (
              <Chip color='secondary' variant="outlined" size="small" key={index} label={tag} />
            ))
          }
        </Box>
      ),
      sortComparator: (v1, v2) => {
        const s1 = v1[0] || ' ';
        const s2 = v2[0] || ' ';
        return s1[0].localeCompare(s2[0]);
      },
    },
    {
      field: 'interlocuteur',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.interlocuteur),
      width: 200,
    },
    {
      field: 'phone',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.phone),
      width: 200,
    },
    {
      field: 'email',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.email),
      sortable: false,
      width: 200,
    },
    {
      field: 'notes', // TODO: this should display the latest note
      sortable: false,
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.notes),
      width: 400,
    },
  ];

  useEffect(() => {
    const fromParam = searchParams.get('from');
    switch (fromParam) {
      case 'accountCreated':
        AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.accountCreated), dispatch);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    try {
      setLoading(true);
      if (clients) {
        const clientRows = clients.filter((c: ClientType) => !!c.nextSuivi);
        clientRows.sort((a:ClientType, b:ClientType) => {
          if (!a.nextSuivi || !b.nextSuivi) return 0;
          return a.nextSuivi.seconds - b.nextSuivi.seconds;
        });
        setRows(clientRows);
        dispatch(setOrderedClientsList(clientRows));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  }, [clients]);

  return (
    <Layout title="Suivis">
      <Spinner show={false}/>
      <Box sx={{
        minHeight: 'calc(100vh - 64px)', width: '100%', padding: '32px 12px 12px', '@media (max-width:565px)': { padding: '8px 0 0' },
      }}>
        <Box sx={{ height: '100%', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            rowsPerPageOptions={[25, 50, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            localeText={(language === SupportedLanguages.FR) ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={(param) => {
              dispatch(setClientOpenId(param.row.id));
              dispatch(setClientPopupOpen(true));
              dispatch(setNavDisabled(false));
            }}
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '1.2em',
              },
              background: 'white',
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
              },
            }}
          />
          <Popper open={moveSuiviPopoverOpen} anchorEl={moveSuiviPopoverAnchorEl} placement='right-start' transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <MoveSuiviPopoverContent onClose={() => setMoveSuiviPopoverOpen(false)} clientId={selectedClientId} />
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </Box>
      <Spinner show={loading}/>
      <MoveSuiviPopup open={addSuiviDialogOpen} onClose={() => setAddSuiviDialogOpen(false)} clientId={selectedClientId} />
      {(searchParams.get('from') === 'accountCreated')
      && <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2251098&vkey=149b48283b001c5c7f15583093ac8b00" style={{ height: 1, width: 1 }}/>}
    </Layout>
  );
};

export default Dashboard;
