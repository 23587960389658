import { PictureAsPdf } from '@mui/icons-material';
import {
  Box, Typography, Container,
} from '@mui/material';
import Layout from '../components/layout/Layout';
import PersonalizedLinks from '../components/PersonalizedLinks/PersonalizedLinks';
import Spinner from '../components/Spinner';
import Utils from '../utils/Utils';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';
import { useAppSelector } from '../store/Hooks';

// TODO: translate pre-filled resources

const resources = [
  {
    title: 'Outils de vente',
    link: 'https://firebasestorage.googleapis.com/v0/b/challenge-action-crm-prod.appspot.com/o/Outils%20de%20vente.pdf?alt=media&token=dcd3c68b-2b14-4be8-b688-22fd87c8969c',
  },
  {
    title: 'Comment augmenter les ventes?',
    // eslint-disable-next-line max-len
    link: 'https://firebasestorage.googleapis.com/v0/b/challenge-action-crm-prod.appspot.com/o/Comment%20augmenter%20les%20ventes%20%2B%20formation.pdf?alt=media&token=3ea44e24-8ec6-46d7-b2b7-06f02bdfa642',
  },
  {
    title: 'Guide sur les ventes',
    link: 'https://firebasestorage.googleapis.com/v0/b/challenge-action-crm-prod.appspot.com/o/Guide%20ventes.pdf?alt=media&token=12f9fccd-46f8-4656-96ef-1a917a3897ae',
  },
  {
    title: 'Prospection et prise de rendez-vous au téléphone',
    // eslint-disable-next-line max-len
    link: 'https://firebasestorage.googleapis.com/v0/b/challenge-action-crm-prod.appspot.com/o/Prospection%20et%20prise%20de%20RDV%20au%20te%CC%81le%CC%81phone.pdf?alt=media&token=6214125c-3db9-46f9-be62-68b15ac4969f',
  },
];

const BoiteOutils: React.FC = () => {
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  return (
    <Layout title="Mes Outils">
      <Box sx={{
        minHeight: 'calc(100vh - 64px)', width: '100%', padding: '32px 12px 12px', '@media (max-width:565px)': { padding: '8px 0 0' },
      }}>
        <Container sx={{ py: 4 }}>
          <Typography variant="h2" color="secondary.contrastText" sx={{ fontWeight: 600 }}>{Utils.getTranslation(language, translator.pages.tools.personalisedLinks)}</Typography>
          <PersonalizedLinks />
          <Typography variant="h2" color="secondary.contrastText" sx={{ fontWeight: 600 }}>{Utils.getTranslation(language, translator.pages.tools.Ressources)}</Typography>
          <Box sx={{
            display: 'flex', gap: 2, alignItems: 'stretch', flexWrap: 'wrap', py: 4,
          }}>
            {resources.map((r, i) => (
              <a
                key={i}
                href={r.link}
                target="_blank"
                className="boite-a-outils-link"
                download>
                <PictureAsPdf />
                {r.title}
              </a>
            ))}
          </Box>
        </Container>
      </Box>
      <Spinner show={false}/>
    </Layout>
  );
};

export default BoiteOutils;
