import '../../theme/css/global.css';
import {
  AppBar, Box, Button, Container, Dialog, IconButton, Slide, Toolbar, Typography,
} from '@mui/material';

import {
  forwardRef, useState,
} from 'react';
import {
  CloseOutlined,
} from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import {
  Formik,
} from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import AlertUtils from '../../utils/AlertUtil';
import SuiviDateInput from '../SuiviDateInput';
import Spinner from '../Spinner';
import ClientService from '../../services/ClientService';
import Utils from '../../utils/Utils';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import translator from '../../theme/translator.json';

type Props = {
    open: boolean,
    onClose: () => void
    clientId: string,
};

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const validationSchema = yup.object({
  date: yup.string().required('Ce champ est requis'),
});

const MoveSuiviPopup: React.FC<Props> = ({
  open, onClose, clientId,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {Utils.getTranslation(language, translator.components.moveSuiviPopup.moveSuivi)}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                onClose();
              }}
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container sx={{ py: 3 }}>
          <Formik
            initialValues={{
              date: '',
              customDate: dayjs().unix() * 1000,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                setLoading(true);
                const newSuiviDate = (values.date === 'custom')
                  ? Timestamp.fromDate(new Date(values.customDate)) : Timestamp.fromDate(new Date(values.date));
                await ClientService.updateNextSuiviDate(user.id, clientId, newSuiviDate);
                resetForm();
                onClose();
                setLoading(false);
                AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.suiviDateModified), dispatch);
              } catch (e) {
                setLoading(false);
                console.error(e);
                AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
              }
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ pb: 2 }}>
                  <SuiviDateInput />
                </Box>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                  {Utils.getTranslation(language, translator.general.deplacer)}
                </Button>
              </form>
            )}
          </Formik>
        </Container>
      </Dialog>
      <Spinner show={loading}/>
    </>
  );
};

export default MoveSuiviPopup;
