import {
  Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, styled, Typography,
} from '@mui/material';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  FormatListBulleted, Groups3, Person, Star,
} from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import ClientType from '../../../models/ClientType';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import AlertUtils from '../../../utils/AlertUtil';
import ClientService from '../../../services/ClientService';
import ClientTypes from '../../../models/enums/ClientTypes';
import clientTypeOptions from '../../../models/ClientTypeOptions';
import Utils from '../../../utils/Utils';
import translator from '../../../theme/translator.json';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';

type Props = {
    client: ClientType,
};

const ClientActions: React.FC<Props> = ({ client }) => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [clientCopy, setClientCopy] = useState(client);

  useEffect(() => {
    setClientCopy(client);
  }, [client]);

  const CustomRadio = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.primary.main,
    padding: '1px 10px',
    transition: 'border 200ms ease',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 0,
    marginRight: 0,
  }));

  const renderClientTypeIcon = (option: any, value: ClientTypes) => {
    switch (option.value) {
      case ClientTypes.RESERVE:
        return (
          <>
            <FormatListBulleted color={value === option.value ? 'secondary' : 'primary'} fontSize="small" />
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{option.label[language]}</Typography>
          </>
        );
      case ClientTypes.PROSPECT:
        return (
          <>
            <Groups3 color={value === option.value ? 'secondary' : 'primary'} fontSize="small" />
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{option.label[language]}</Typography>
          </>
        );
      case ClientTypes.CLIENT:
        return (
          <>
            <Person color={value === option.value ? 'secondary' : 'primary'} fontSize="small" />
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{option.label[language]}</Typography>
          </>
        );
      case ClientTypes.PROPOSITION:
        return (
          <>
            <Star color={value === option.value ? 'secondary' : 'primary'} fontSize="small" />
            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{option.label[language]}</Typography>
          </>
        );
      case ClientTypes.PEU_INTERESSANT:
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
          }}>
            <ArchiveIcon fontSize="small" />
            <Typography>{option?.label[language]}</Typography>
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          type: clientCopy.type || ClientTypes.CLIENT,
          priority: clientCopy.priority || 99,
        }}
        enableReinitialize
        validationSchema={yup.object({
          type: yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (user.id) {
              const newClient = {
                type: values.type || ClientTypes.CLIENT,
                priority: values.priority || 99,
              };
              setClientCopy({
                ...newClient,
                notes: client.notes,
                visibility: client.visibility,
                id: client.id,
              });
              await ClientService.update(user.id, client.id, newClient);
            }
            setSubmitting(false);
            AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.clientSaved), dispatch);
          } catch (e) {
            console.error(e);
            AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
          }
        }}
      >
        {(formikProps) => (
          <Form>
            <Box sx={{
              display: 'flex', alignItems: 'start', justifyContent: 'space-between', gap: '40px', flexWrap: 'wrap',
            }}>
              <Box>
                <Typography sx={{ fontSize: 16, fontWeight: 600, my: 1 }}>{Utils.getTranslation(language, translator.general.clientTypeLabel)}</Typography>
                <Box sx={{
                  display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2,
                }}>
                  {/* TODO: abstract to component */}
                  <RadioGroup
                    name='type'
                    value={formikProps.values.type}
                    onChange={(event: any, val: string) => {
                      formikProps.setFieldValue('type', val, false);
                      formikProps.handleSubmit();
                    }}
                    sx={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '8px',
                    }}
                  >
                    <Box sx={{
                      display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1,
                    }}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          my: 1,
                        }}>
                          <CustomRadio
                            sx={{
                              border: '2px solid',
                              borderColor: (formikProps.values.type === ClientTypes.PEU_INTERESSANT) ? 'secondary.main' : '#efefef',
                            }}
                            control={
                              <Radio
                                value={ClientTypes.PEU_INTERESSANT}
                                disabled={formikProps.isSubmitting}
                                sx={{
                                  display: 'none',
                                }}
                              />
                            }
                            label={
                              <Box>
                                <Box sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}>
                                  <ArchiveIcon color={formikProps.values.type === ClientTypes.PEU_INTERESSANT ? 'secondary' : 'primary'} fontSize="small" />
                                  <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{Utils.getTranslation(language, translator.general.contactEnAttente)}</Typography>
                                </Box>
                              </Box>
                            }
                          />
                          <KeyboardArrowRightIcon fontSize='small' />
                        </Box>
                        {clientTypeOptions.map((option, index) => {
                          if (option.value === ClientTypes.PROPOSITION
                        || option.value === ClientTypes.RESERVE
                        || option.value === ClientTypes.PROSPECT
                        || option.value === ClientTypes.CLIENT) {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  my: 1,
                                }}>
                                <CustomRadio
                                  sx={{
                                    border: '2px solid',
                                    borderColor: (formikProps.values.type === option.value) ? 'secondary.main' : '#efefef',
                                  }}
                                  control={
                                    <Radio
                                      value={option.value}
                                      disabled={formikProps.isSubmitting}
                                      sx={{
                                        display: 'none',
                                      }}
                                    />
                                  }
                                  label={
                                    <Box>
                                      <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                      }}>
                                        {
                                          renderClientTypeIcon(option, formikProps.values.type)
                                        }
                                      </Box>
                                    </Box>
                                  }
                                />
                                {
                                  index !== 3
                            && <KeyboardArrowRightIcon fontSize='small' />
                                }
                              </Box>
                            );
                          }
                          return <Box key={index}></Box>;
                        })}
                      </Box>
                    </Box>

                  </RadioGroup>

                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 16, fontWeight: 600, my: 1 }}>{Utils.getTranslation(language, translator.general.priority)}</Typography>
                <FormControl sx={{ minWidth: '150px' }}>
                  <Select
                    value={formikProps.values.priority}
                    name='priority'
                    onChange={(event, value: any) => {
                      const valueInt = parseInt(value.props.value, 10);
                      formikProps.setFieldValue('priority', valueInt, false);
                      formikProps.handleSubmit();
                    }}
                    size="small"
                  >
                    <MenuItem value={99}>{Utils.getTranslation(language, translator.general.aucune)}</MenuItem>
                    {
                      [1, 2, 3].map((option : any, index: number) => (
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Form>)}
      </Formik>
    </>
  );
};

export default ClientActions;
