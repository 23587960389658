import {
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';

import { useEffect, useState } from 'react';
import ClientType from '../../../models/ClientType';
import Suivi from '../../../models/Suivi';
import Spinner from '../../Spinner';
import DeleteSuiviConfirmation from './DeleteSuiviConfirmation';
import SuiviCard from './SuiviCard';

type Props = {
    client: ClientType,
    suivis: Suivi[],
    suiviFocusId?: string,
};

const SuivisList: React.FC<Props> = ({ client, suivis, suiviFocusId }) => {
  const [loading, setLoading] = useState(false);
  const [deleteSuiviDialogOpen, setDeleteSuiviDialogOpen] = useState(false);
  const [suiviToDelete, setSuiviToDelete] = useState(undefined) as any | Suivi;

  const [activeSuivis, setActiveSuivis] = useState([]) as any;

  useEffect(() => {
    if (suiviFocusId) {
      const suiviToFocus = document.querySelector(`#s-${suiviFocusId}`);
      if (suiviToFocus) {
        suiviToFocus.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [suiviFocusId, suivis]);

  useEffect(() => {
    setActiveSuivis([...suivis].reverse());
  }, [suivis]);

  const handleOpenDeleteSuiviDialog = (suivi: Suivi) => {
    setDeleteSuiviDialogOpen(true);
    setSuiviToDelete(suivi);
  };

  const handleCloseDeleteSuiviDialog = () => {
    setDeleteSuiviDialogOpen(false);
  };

  return (
    <>
      <Stack sx={{
        paddingBottom: '200px',
      }} spacing={3}>
        {(!activeSuivis || !activeSuivis[0] || !activeSuivis.find((s:Suivi) => dayjs(s.date.seconds * 1000).isSame(dayjs(), 'day'))) && <SuiviCard
          clientId={client.id}
          setLoading={setLoading}
          handleOpenDeleteSuiviDialog={handleOpenDeleteSuiviDialog}
        />}
        {
          activeSuivis.map((suivi: Suivi, index: number) => (
            <SuiviCard
              key={index}
              clientId={client.id}
              suivi={suivi}
              setLoading={setLoading}
              handleOpenDeleteSuiviDialog={handleOpenDeleteSuiviDialog}
            />
          ))
        }
      </Stack>
      <Spinner show={loading}/>
      <DeleteSuiviConfirmation
        open={deleteSuiviDialogOpen}
        handleClose={handleCloseDeleteSuiviDialog}
        suivi={suiviToDelete}
      />
    </>
  );
};

export default SuivisList;
