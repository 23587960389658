import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import '../../../theme/css/global.css';
import { useEffect, useState } from 'react';
import {
  deleteObject, getDownloadURL, ref, uploadBytesResumable,
} from 'firebase/storage';
import {
  Delete,
} from '@mui/icons-material';
import Folder from '../../../theme/assets/folder.png';
import { storage } from '../../../FirebaseConfig';
import Utils from '../../../utils/Utils';
import { useAppSelector } from '../../../store/Hooks';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import translator from '../../../theme/translator.json';
import ClientService from '../../../services/ClientService';
import ClientType from '../../../models/ClientType';
import User from '../../../models/User';

const FilesSave: React.FC<{client:ClientType}> = ({ client }) => {
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [file, setFile] = useState('') as any;
  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState('') as any;
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const user = useAppSelector((state) => state.user.user) as User;

  const [clientCopy, setClientCopy] = useState(client);

  useEffect(() => {
    setClientCopy(client);
  }, [client]);

  function handleChange(event:any) {
    if (event.target.files[0].size < 16777216) {
      setFile(event.target.files[0]);
      handleUpload(event.target.files[0]);
    }
  }

  function handleUpload(f:any) {
    if (!f) {
      // eslint-disable-next-line no-alert
      alert('Please choose a file first!');
    }

    const storageRef = ref(storage, `/user-files/${user.id}/${clientCopy.id}/${f.name}`);
    const uploadTask = uploadBytesResumable(storageRef, f);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );

        // update progress
        console.log(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);

          // save to client fiche
          let newClient;
          if (clientCopy.files) {
            newClient = {
              ...clientCopy,
              files: [
                ...clientCopy.files,
                {
                  name: f.name,
                  url,
                },
              ],
            };
          } else {
            newClient = {
              ...clientCopy,
              files: [
                {
                  name: f.name,
                  url,
                },
              ],
            };
          }

          setClientCopy(newClient);
          ClientService.update(user.id, client.id, newClient);

          setFile('');
          setFileUploadOpen(false);
        });
      },
    );
  }

  function handleDeleteFile(f:any) {
    const desertRef = ref(storage, f.url);

    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      if (!clientCopy || !clientCopy.files) return;
      const clientFilesNew = clientCopy.files.filter((obj:any) => obj.url !== f.url);

      const newClient = {
        ...clientCopy,
        files: clientFilesNew,
      };

      setClientCopy(newClient);
      ClientService.update(user.id, client.id, newClient);
      setFileToDelete('');
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error);
      setFileToDelete('');
    });
  }

  return (
    <>
      <Box style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10,
      }}>
        {clientCopy && clientCopy.files && clientCopy.files.map((f:any, i:number) => (
          <Box style={{
            padding: '4px 8px',
            border: '2px solid #efefef',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            color: '#16213E',
          }} key={i}>
            <a href={f.url} target="_blank">
              {f.name}
            </a>
            <Delete fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => {
              setFileToDelete(f);
              setDeleteFileDialogOpen(true);
            }} />
          </Box>
        ))}
        {(!clientCopy.files || clientCopy.files.length < 10) && <Box onClick={() => setFileUploadOpen(true)} sx={{
          padding: '4px 8px',
          width: 40,
          backgroundColor: 'secondary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
        }}>+</Box>}
      </Box>
      <Box>
        {fileUploadOpen && <Box sx={{ position: 'relative', mx: 'auto', my: 2 }}>
          <label htmlFor="file" className="pa-field-label-file">
            <div className="pa-file-input-icon">
              <img src={Folder}></img>
            </div>
            { (file && file.name)
              ? <p className="pa-file-input-text">{file.name}</p>
              : <p className="pa-file-input-text">{Utils.getTranslation(language, translator.components.importPopup.selectFile)}</p>
            }
          </label>
          <input
            id="file"
            className="pa-file-input"
            name="file"
            type="file"
            onChange={handleChange}/>
        </Box>}
      </Box>
      <Dialog
        open={deleteFileDialogOpen}
        onClose={() => setDeleteFileDialogOpen(false)}
      >
        <DialogTitle>
          {Utils.getTranslation(language, translator.components.ficheClient.deleteFileConfirmationTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Utils.getTranslation(language, translator.components.ficheClient.deleteFileConfirmationDescription)}
            {fileToDelete.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteFileDialogOpen(false)}>{Utils.getTranslation(language, translator.components.promptForCredentials.form.cancel)}</Button>
          <Button onClick={() => {
            setDeleteFileDialogOpen(false);
            handleDeleteFile(fileToDelete);
          }} autoFocus>
            {Utils.getTranslation(language, translator.components.promptForCredentials.form.submit)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilesSave;
