import { useMemo, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Roles from '../models/enums/Roles';
import User from '../models/User';
import { useAppSelector } from '../store/Hooks';

const AdminRoutes: React.FC = () => {
  const user = useAppSelector((state) => state.user.user) as User;

  const [permissions, setPermissions] = useState(Roles.USER);

  useMemo(() => {
    if (user) {
      setPermissions(user.role);
    } else {
      setPermissions(Roles.USER);
    }
  }, [user]);
  return (
    permissions === Roles.ADMIN ? <Outlet/> : <Navigate to='/'/>
  );
};

export default AdminRoutes;
