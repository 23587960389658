import {
  Box,
  DialogContent, DialogTitle,
} from '@mui/material';
import CheminemanetImgDesktop from '../../../theme/assets/cheminement-client-desktop.png';
import CheminemanetImgDesktopEn from '../../../theme/assets/cheminement-client-desktop-en.png';
import CheminemanetImgMobile from '../../../theme/assets/cheminement-client-mobile.png';
import CheminemanetImgMobileEn from '../../../theme/assets/cheminement-client-mobile-en.png';
import Utils from '../../../utils/Utils';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import translator from '../../../theme/translator.json';
import { useAppSelector } from '../../../store/Hooks';

const Step1: React.FC = () => {
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>{Utils.getTranslation(language, translator.components.tutorialPopup.step1Title)}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box sx={{
          display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center', my: 4,
        }}>
          {(language === SupportedLanguages.FR)
            ? <img style={{ maxWidth: '700px' }} src={CheminemanetImgDesktop}></img>
            : <img style={{ maxWidth: '700px' }} src={CheminemanetImgDesktopEn}></img>}
        </Box>
        <Box sx={{
          display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', my: 4,
        }}>
          {(language === SupportedLanguages.FR)
            ? <img style={{ maxWidth: '700px' }} src={CheminemanetImgMobile}></img>
            : <img style={{ maxWidth: '700px' }} src={CheminemanetImgMobileEn}></img>}
        </Box>
      </DialogContent>
    </>

  );
};

export default Step1;
