import { Upgrade } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import PricingPlan from '../models/enums/PricingPlans';
import { useAppSelector } from '../store/Hooks';
import translator from '../theme/translator.json';
import Utils from '../utils/Utils';

const UpgradePage: React.FC = () => {
  const { user, language } = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  const upgradePlan = async () => {
    navigate('/pricing');
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            maxWidth: '900px',
            marginLeft: 'auto',
            marginRight: 'auto',
            gap: '15px',
            color: 'white',
          }}
        >
          {user && user.plan === PricingPlan.PREMIUM && <>
            <Upgrade sx={{ fontSize: 150 }} />
            <Typography variant="h1">
              {Utils.getTranslation(language, translator.pages.upgrade.proAlready)}
            </Typography>
            <Typography variant="body1" align="center">
              {Utils.getTranslation(language, translator.pages.upgrade.proAlreadyDesc)}
            </Typography>
            <Button onClick={() => navigate('/')} variant="contained">{Utils.getTranslation(language, translator.pages.notFound.toDashboard)}</Button>
          </>}
          {user && user.plan === PricingPlan.FREEMIUM && <>
            <Upgrade sx={{ fontSize: 150 }} />
            <Typography variant="h1">
              {Utils.getTranslation(language, translator.pages.upgrade.upgrade)}
            </Typography>
            <Typography variant="body1" align="center">
              {Utils.getTranslation(language, translator.pages.upgrade.upgradeDesc)}
            </Typography>
            <Button onClick={() => upgradePlan()} variant="contained">{Utils.getTranslation(language, translator.pages.upgrade.upgradeCTA)}</Button>
            <Button onClick={() => navigate('/')} variant="text">{Utils.getTranslation(language, translator.pages.notFound.toDashboard)}</Button>
          </>}
        </Box>
      </Container>
    </>
  );
};

export default UpgradePage;
