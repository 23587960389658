import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@mui/material';
import { useState } from 'react';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import VisibilityState from '../../../models/enums/VisibilityState';
import Suivi from '../../../models/Suivi';
import SuiviService from '../../../services/SuiviService';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import AlertUtil from '../../../utils/AlertUtil';
import Spinner from '../../Spinner';
import translator from '../../../theme/translator.json';
import Utils from '../../../utils/Utils';

type Props = {
  open: boolean,
  handleClose: () => void,
  suivi: Suivi,
};

const DeleteSuiviConfirmation: React.FC<Props> = ({
  open, handleClose, suivi,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [loading, setLoading] = useState(false);

  const deleteSuivi = async () => {
    try {
      setLoading(true);
      await SuiviService.update(user.id, suivi.id, { visibility: VisibilityState.HIDDEN });
      setLoading(false);
      handleClose();

      AlertUtil.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.noteDeleted), dispatch);
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtil.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Utils.getTranslation(language, translator.components.deleteSuiviConfirmation.dialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Utils.getTranslation(language, translator.components.deleteSuiviConfirmation.dialogDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {Utils.getTranslation(language, translator.general.cancel)}
          </Button>
          <Button variant="contained" color="error" onClick={deleteSuivi} autoFocus>
            {Utils.getTranslation(language, translator.general.delete)}
          </Button>
        </DialogActions>
      </Dialog>
      <Spinner show={loading}/>
    </>
  );
};

export default DeleteSuiviConfirmation;
