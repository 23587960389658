import '../../theme/css/global.css';
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Toolbar, Typography,
} from '@mui/material';

import {
  forwardRef, useState,
} from 'react';

import { TransitionProps } from '@mui/material/transitions';
import { CloseOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import { Formik } from 'formik';
import readXlsxFile from 'read-excel-file';
import Spinner from '../Spinner';
import Folder from '../../theme/assets/folder.png';
import ClientService from '../../services/ClientService';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import UserService from '../../services/UserService';
import clientTypeOptions from '../../models/ClientTypeOptions';
import ClientTypes from '../../models/enums/ClientTypes';
import PricingPlan from '../../models/enums/PricingPlans';
import AlertUtils from '../../utils/AlertUtil';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';
import PlansRestrictions from '../../models/enums/PlansRestrictions';

type Props = {
    open: boolean,
    onClose: () => void
};

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const validationSchema = yup.object({
  file: yup.mixed().required(),
});

const ImportPopup: React.FC<Props> = ({
  open, onClose,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [loading, setLoading] = useState(false);
  const [displayRows, setDisplayRows] = useState() as any;
  const [errorMessage, setErrorMessage] = useState('');

  const closePopup = () => {
    setErrorMessage('');
    setDisplayRows([]);
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          if (!loading) closePopup();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Container maxWidth="xl">
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {Utils.getTranslation(language, translator.components.importPopup.import)}
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => {
                  if (!loading) closePopup();
                }}
                aria-label="close"
              >
                <CloseOutlined />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
        <Container sx={{ py: 3 }}>
          <Typography variant="body1">
            {Utils.getTranslation(language, translator.components.importPopup.description)}
            <span> </span>
            {/* TODO: have a different example file for different languages */}
            <a href="https://actionclient.com/app-bin/crm-import.xlsx" style={{ color: '#16213E', fontWeight: 600 }}>
              {Utils.getTranslation(language, translator.components.importPopup.linkToExample)}
            </a>
          </Typography>
          <Typography sx={{ mt: 2, fontWeight: 600 }}>{Utils.getTranslation(language, translator.components.importPopup.formatAttendu)}</Typography>
          <Box sx={{ padding: 2, border: '1px solid #efefef', my: 2 }}>
            <table style={{ width: '100%', fontSize: '0.9rem' }}>
              <tbody>
                <tr style={{ background: '#efefef', fontWeight: 600 }}>
                  <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.client)}</td>
                  <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.interlocuteur)}</td>
                  <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.telephone)}</td>
                  <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.courriel)}</td>
                  <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.Notes)}</td>
                </tr>
                <tr style={{ background: '#ffffff' }}>
                  <td style={{ padding: '4px 12px' }}>Nebula Inc</td>
                  <td style={{ padding: '4px 12px' }}>Marie-Claire Lefevre</td>
                  <td style={{ padding: '4px 12px' }}>+33 7 56 78 90 12</td>
                  <td style={{ padding: '4px 12px' }}>marie-claire.lefevre@nebulainc.com</td>
                  <td style={{ padding: '4px 12px' }}></td>
                </tr>
                <tr style={{ background: '#efefef' }}>
                  <td style={{ padding: '4px 12px' }}>Vega Corp</td>
                  <td style={{ padding: '4px 12px' }}>Juan Rodriguez</td>
                  <td style={{ padding: '4px 12px' }}>+1 323 555 1212</td>
                  <td style={{ padding: '4px 12px' }}>juan.rodriguez@vegacorp.com</td>
                  <td style={{ padding: '4px 12px' }}></td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Formik
            initialValues={{
              file: null as any,
              rowsToImport: [] as any,
              type: ClientTypes.RESERVE,

            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              setErrorMessage(Utils.getTranslation(language, translator.components.importPopup.importationEnCours));
              const userTotalClients = user.documentsCounter.clientsCounter + values.rowsToImport.length;
              if (user.plan === PricingPlan.FREEMIUM && userTotalClients > PlansRestrictions.FREEMIUM.clientsCount) {
                AlertUtils.createErrorAlert(Utils.getTranslation(
                  language,
                  translator.errorMessages.plan.freeTierClientsLimitedImport,
                  { numberClients: PlansRestrictions.FREEMIUM.clientsCount.toString() },
                ), dispatch);
                setErrorMessage('');
                setLoading(false);
                return;
              }
              await Promise.all(values.rowsToImport.map(async (row : any) => ClientService.createFast(
                user.id,
                (row[0]) ? row[0].toString() : '',
                (row[1]) ? row[1].toString() : '',
                (row[2]) ? row[2].toString() : '',
                (row[3]) ? row[3].toString() : '',
                (row[4]) ? row[4].toString() : '',
                values.type || ClientTypes.CLIENT,
              )));
              const documentCounterTemp = {
                ...user.documentsCounter,
                clientsCounter: user.documentsCounter.clientsCounter + values.rowsToImport.length,
              };
              await UserService.update(user.id, { documentsCounter: documentCounterTemp });
              setLoading(false);
              closePopup();
            }}>
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <FormControl sx={{ my: 3 }}>
                  <InputLabel id="type-client-select">{Utils.getTranslation(language, translator.components.importPopup.listeImportation)}</InputLabel>
                  <Select
                    labelId="type-client-select"
                    id="demo-simple-select"
                    name="type"
                    value={formik.values.type}
                    label={Utils.getTranslation(language, translator.components.importPopup.listeImportation)}
                    onChange={formik.handleChange}
                    sx={{ minWidth: 210 }}
                  >
                    {clientTypeOptions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>{option.label[language]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ position: 'relative', mx: 'auto', my: 2 }}>
                    <label htmlFor="file" className="pa-field-label-file">
                      <div className="pa-file-input-icon">
                        <img src={Folder}></img>
                      </div>
                      { (formik.values.file && formik.values.file.name)
                        ? <p className="pa-file-input-text">{formik.values.file.name}</p>
                        : <p className="pa-file-input-text">{Utils.getTranslation(language, translator.components.importPopup.selectFile)}</p>
                      }
                    </label>
                    <input
                      id="file"
                      className="pa-file-input"
                      name="file"
                      type="file"
                      disabled={formik.isSubmitting || loading}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          const file = event.currentTarget.files[0];
                          let counter = 0;
                          let tempRows = [] as any;
                          let hasError = false;
                          const rowInputSchema = yup.object({
                            client: yup.string().max(500),
                            interlocuteur: yup.string().max(500),
                            telephone: yup.string().max(500),
                            courriel: yup.string().max(500),
                            notes: yup.string().max(2000),
                          });
                          readXlsxFile(file).then((rows) => {
                            if (rows.length > 1500) {
                              tempRows = [];
                              setDisplayRows(tempRows);
                              setErrorMessage(Utils.getTranslation(language, translator.errorMessages.import.bulkImportLimit));
                              return;
                            }
                            // eslint-disable-next-line no-restricted-syntax
                            for (const row of rows) {
                              if (counter === 0) {
                                if (
                                  row[0] !== 'Client'
                                  && row[0] !== 'client'
                                ) {
                                  hasError = true;
                                  setErrorMessage(Utils.getTranslation(language, translator.errorMessages.import.badFormat.client));
                                  break;
                                }
                                if (
                                  row[1] !== 'Interlocuteur'
                                  && row[1] !== 'interlocuteur'
                                  && row[1] !== 'interlocuteurs'
                                  && row[1] !== 'Interlocuteurs'
                                  && row[1] !== 'Contact'
                                  && row[1] !== 'contact'
                                  && row[1] !== 'Contact person'
                                  && row[1] !== 'contact person'
                                ) {
                                  hasError = true;
                                  setErrorMessage(Utils.getTranslation(language, translator.errorMessages.import.badFormat.interlocuteur));
                                  break;
                                }
                                if (
                                  row[2] !== 'Téléphone'
                                  && row[2] !== 'téléphone'
                                  && row[2] !== 'Telephone'
                                  && row[2] !== 'telephone'
                                  && row[2] !== 'Tél'
                                  && row[2] !== 'Tel'
                                  && row[2] !== 'tél'
                                  && row[2] !== 'tel'
                                  && row[2] !== 'phone'
                                  && row[2] !== 'Phone'
                                  && row[2] !== 'Phone number'
                                  && row[2] !== 'phone number'
                                ) {
                                  hasError = true;
                                  setErrorMessage(Utils.getTranslation(language, translator.errorMessages.import.badFormat.telephone));
                                  break;
                                }
                                if (
                                  row[3] !== 'Courriel'
                                  && row[3] !== 'courriel'
                                  && row[3] !== 'mail'
                                  && row[3] !== 'email'
                                  && row[3] !== 'Email'
                                ) {
                                  hasError = true;
                                  setErrorMessage(Utils.getTranslation(language, translator.errorMessages.import.badFormat.courriel));
                                  break;
                                }
                                if (
                                  row[4] !== 'Notes'
                                  && row[4] !== 'notes'
                                  && row[4] !== 'note'
                                ) {
                                  hasError = true;
                                  setErrorMessage(Utils.getTranslation(language, translator.errorMessages.import.badFormat.notes));
                                  break;
                                }
                              }
                              if (counter > 0) {
                                // hard set values
                                row[1] = row[1] || '';
                                row[2] = row[2] || '';
                                row[3] = row[3] || '';
                                row[4] = row[4] || '';
                                // swt schema to validate
                                const rowObj = {
                                  client: row[0],
                                  interlocuteur: row[1],
                                  telephone: row[2],
                                  courriel: row[3],
                                  notes: row[4],
                                };
                                if (rowInputSchema.isValidSync(rowObj)) {
                                  tempRows.push(row);
                                } else {
                                  // TODO: display to user which rows can't be imported and why
                                  console.log('Row with error');
                                  console.log(row);
                                }
                              }
                              counter += 1;
                            }
                            if (hasError) tempRows = [];
                            if (!hasError) setErrorMessage('');
                            if (!hasError) formik.setFieldValue('file', file);
                            if (!hasError) formik.setFieldValue('rowsToImport', tempRows);
                            setDisplayRows(tempRows);
                          });
                        }
                      }} />
                  </Box>
                </Box>
                <Box sx={{ py: 2 }}>
                  {
                    (errorMessage) && <Typography sx={{ color: '#d32f2f', fontWeight: 600, textAlign: 'center' }}>{errorMessage}</Typography>
                  }
                  {
                    (displayRows)
                    && <>
                      <Typography variant="h4" sx={{ my: 2 }}>{Utils.getTranslation(language, translator.components.importPopup.aImporter)}</Typography>
                      <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                        <table style={{ width: '100%', fontSize: '0.9rem' }}>
                          <tbody>
                            <tr style={{ background: '#efefef', fontWeight: 600 }}>
                              <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.client)}</td>
                              <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.interlocuteur)}</td>
                              <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.telephone)}</td>
                              <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.courriel)}</td>
                              <td style={{ padding: '4px 12px' }}>{Utils.getTranslation(language, translator.formLabels.Notes)}</td>
                            </tr>
                            {displayRows.map((row : any, index:number) => (
                              <tr style={{ background: index % 2 ? '#efefef' : '#ffffff' }} key={index}>
                                <td style={{ padding: '4px 12px' }}>{row[0]}</td>
                                <td style={{ padding: '4px 12px' }}>{row[1]}</td>
                                <td style={{ padding: '4px 12px' }}>{row[2]}</td>
                                <td style={{ padding: '4px 12px' }}>{row[3]}</td>
                                <td style={{ padding: '4px 12px' }}>{row[4]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </>
                  }
                </Box>
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting || !formik.values.file || !formik.values.type}>
                  {Utils.getTranslation(language, translator.components.importPopup.import)}
                </Button>
                <Spinner show={loading || formik.isSubmitting}/>
              </form>
            )}
          </Formik>
        </Container>
      </Dialog>
    </>
  );
};

export default ImportPopup;
