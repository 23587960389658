import { useEffect, useState } from 'react';
import {
  NavLink, useLocation, useParams, useSearchParams,
} from 'react-router-dom';
import {
  DataGrid, frFR, enUS, GridCellCheckboxRenderer, GridColDef, GridHeaderCheckbox, GridSelectionModel, GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import {
  Box, Button, Chip, Typography,
} from '@mui/material';
import Layout from '../components/layout/Layout';
import Spinner from '../components/Spinner';
import Utils from '../utils/Utils';
import translator from '../theme/translator.json';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import AlertUtils from '../utils/AlertUtil';
import ClientTypes from '../models/enums/ClientTypes';
import DeleteClientConfirmation from '../common/DeleteClientConfirmation';
import {
  setClientOpenId, setClientPopupOpen, setNavDisabled, setOrderedClientsList,
} from '../store/slices/ClientSlice';

const Clients: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const { clients } = useAppSelector((state) => state.user);

  const [rows, setRows] = useState([]) as any[];
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectedClients, setSelectedClients] = useState<any>([]);
  const [deleteClientsConfirmationOpen, setDeleteClientsConfirmationOpen] = useState(false);
  const [showSecondNav, setShowSecondNav] = useState(false);
  const [title, setTitle] = useState('Clients');

  const { filter, secondnav } = useParams();
  const { state } = useLocation();

  const columns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: (selectedClients.length) ? 150 : 50,
      renderHeader: (params) => (
        <Box sx={{
          display: 'flex', alignItems: 'center', width: (selectedClients.length) ? 150 : 50, transition: 'width 200ms ease',
        }}>
          <GridHeaderCheckbox {...params} />
          {!!selectedClients.length
          && <Button
            color='error'
            variant='contained'
            size='small'
            onClick={() => setDeleteClientsConfirmationOpen(true)}>
            {Utils.getTranslation(language, translator.general.delete)}
          </Button>
          }
        </Box>
      ),
      renderCell: (params) => (
        <Box sx={{
          display: 'flex', alignItems: 'center', width: (selectedClients.length) ? 150 : 50, transition: 'width 200ms ease',
        }}>
          <GridCellCheckboxRenderer {...params} />
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.nomDuClient),
      width: 250,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <Typography sx={{
              maxWidth: 180,
              overflow: 'hidden',
              fontSize: '1.1em',
              fontWeight: 600,
              paddingRight: 1,
            }}>
              {params.row.name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'priority',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.priority),
      renderCell: (params) => (
        <Typography sx={{ textAlign: 'center', width: '100%', fontWeight: 600 }}>
          {params.row.priority !== 99 && params.row.priority}
        </Typography>
      ),
      sortComparator: (v1, v2) => {
        const s1 = v1 || 99;
        const s2 = v2 || 99;
        return s1 - s2;
      },
    },
    {
      field: 'tags',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.categories),
      width: 150,
      disableExport: true,
      renderCell: (params) => (
        <Box sx={{
          display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'flex-start', width: '100%', overflowX: 'auto', height: '100%',
        }}>
          {
            params.row.tags.map((tag: string, index: number) => (
              <Chip color='secondary' variant="outlined" size="small" key={index} label={tag} />
            ))
          }
        </Box>
      ),
      sortComparator: (v1, v2) => {
        const s1 = v1[0] || ' ';
        const s2 = v2[0] || ' ';
        return s1[0].localeCompare(s2[0]);
      },
    },
    {
      field: 'interlocuteur',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.interlocuteur),
      width: 200,
    },
    {
      field: 'phone',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.phone),
      width: 200,
    },
    {
      field: 'email',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.email),
      sortable: false,
      width: 200,
    },
    {
      field: 'notes',
      headerName: Utils.getTranslation(language, translator.pages.dashboard.tables.notes),
      sortable: false,
      width: 400,
    },
  ];

  useEffect(() => {
    const fromParam = searchParams.get('from');
    switch (fromParam) {
      case 'accountCreated':
        AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.accountCreated), dispatch);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (clients) {
      const clientsRows: any[] = [];
      let tempActiveFilter = secondnav || filter || '';
      setShowSecondNav(false);
      if (tempActiveFilter === 'listes') {
        tempActiveFilter = 'reserve';
        setShowSecondNav(true);
      }
      if (secondnav) setShowSecondNav(true);

      clients.forEach((client:any) => {
        if (tempActiveFilter
          && (client.type === tempActiveFilter)
        ) {
          clientsRows.push(client);
        } else if (!tempActiveFilter
          && (client.type === ClientTypes.CLIENT)) {
          clientsRows.push(client);
        }
      });
      setRows(clientsRows);
    }
  }, [clients, filter, secondnav]);

  useEffect(() => {
    dispatch(setOrderedClientsList(rows));
    setLoading(false);
  }, [rows]);

  useEffect(() => {
    if (state && state.title) setTitle(state.title);
  }, [state]);

  return (
    <Layout title={title}>
      <Spinner show={loading}/>
      <Box sx={{
        minHeight: 'calc(100vh - 64px)', width: '100%', padding: showSecondNav ? '32px 12px 45px' : '32px 12px 12px', '@media (max-width:565px)': { padding: '8px 0 0' },
      }}>
        <Box sx={{ height: '100%', width: '100%' }}>
          {showSecondNav && <Box sx={{
            width: '100%', background: 'white', mb: '-5px', borderRadius: '4px', display: { xs: 'none', md: 'flex' },
          }}>
            <NavLink
              to="/clients/listes"
              className="client-nav-link"
              style={({ isActive }) => (isActive ? { background: '#F1F1E6', color: '#F55C47' } : { color: '#16213E' })
              }
              end
            >
              {Utils.getTranslation(language, translator.pages.dashboard.tables.banqueContacts)}
            </NavLink>
            <NavLink
              to="/clients/listes/peu-interessant"
              className="client-nav-link"
              style={({ isActive }) => (isActive ? { background: '#F1F1E6', color: '#F55C47' } : { color: '#16213E' })
              }
            >
              {Utils.getTranslation(language, translator.pages.dashboard.tables.contactsAttente)}
            </NavLink>
          </Box>}
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            onRowClick={(param) => {
              dispatch(setClientOpenId(param.row.id));
              dispatch(setClientPopupOpen(true));
              dispatch(setNavDisabled(false));
            }}
            rowsPerPageOptions={[25, 50, 100]}
            experimentalFeatures={{ newEditingApi: true }}
            localeText={(language === SupportedLanguages.FR) ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText }
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '1.2em',
              },
              '.MuiTextField-root div': {
                border: '1px solid black',
                borderRadius: '4px',
                padding: '2px',
              },
              background: 'white',
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
              },
            }}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
              setSelectedClients(rows.filter((row:any) => newSelectionModel.includes(row.id)));
            }}
            selectionModel={selectionModel}
            disableSelectionOnClick
          />
        </Box>
      </Box>
      <DeleteClientConfirmation
        open={deleteClientsConfirmationOpen}
        handleClose={() => setDeleteClientsConfirmationOpen(false)}
        clients={selectedClients}
      />
    </Layout>
  );
};

export default Clients;
