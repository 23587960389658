import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import VisibilityState from '../models/enums/VisibilityState';
import Suivi from '../models/Suivi';
import ClientService from '../services/ClientService';
import SuiviService from '../services/SuiviService';
import UserService from '../services/UserService';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import AlertUtils from '../utils/AlertUtil';
import Spinner from '../components/Spinner';
import ClientType from '../models/ClientType';
import Utils from '../utils/Utils';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';

type Props = {
  open: boolean,
  handleClose: () => void,
  clients: ClientType[],
  handleCloseSummary?: () => void
};

const DeleteClientConfirmation: React.FC<Props> = ({
  open, handleClose, clients, handleCloseSummary,
}) => {
  const dispatch = useAppDispatch();
  const { user, suivis } = useAppSelector((state) => state.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [suivisList, setSuivisList] = useState([]) as any | Suivi[];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (clients && suivis && open) {
        setSuivisList(suivis);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  }, [suivis, clients, open]);

  const deleteClients = async () => {
    try {
      setLoading(true);

      let suivisDeleted = 0;
      if (suivisList) {
        // eslint-disable-next-line no-restricted-syntax
        for (const client of clients) {
          const clientSuivisList = suivisList.filter((suivi: Suivi) => suivi.clientId === client.id);

          // eslint-disable-next-line no-restricted-syntax
          for (const suivi of clientSuivisList) {
            SuiviService.update(user.id, suivi.id, { visibility: VisibilityState.HIDDEN });
            suivisDeleted += 1;
          }

          // eslint-disable-next-line no-await-in-loop
          await ClientService.update(user.id, client.id, {
            visibility: VisibilityState.HIDDEN,
          });
        }

        const documentCounterTemp = {
          ...user.documentsCounter,
          clientsCounter: user.documentsCounter.clientsCounter - clients.length,
          suivisCounter: user.documentsCounter.suivisCounter - suivisDeleted,
        };
        await UserService.update(user.id, { documentsCounter: documentCounterTemp });

        handleClose();
      } else {
        throw new Error('SuivisList is undefined');
      }

      setLoading(false);
      if (handleCloseSummary) {
        handleCloseSummary();
      }
      AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.clientDeleted), dispatch);
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {clients.length > 1
          ? <DialogTitle id="alert-dialog-title">
            {Utils.getTranslation(language, translator.components.deleteClient.deleteConfirmPlural)}
          </DialogTitle>
          : <DialogTitle id="alert-dialog-title">
            {Utils.getTranslation(language, translator.components.deleteClient.deleteConfirm)}
          </DialogTitle>
        }

        <DialogContent>
          {clients.length > 1
            ? <Typography id="alert-dialog-description">
              {Utils.getTranslation(language, translator.components.deleteClient.deletePromptPlural)}
            </Typography>
            : <Typography id="alert-dialog-description">
              {Utils.getTranslation(language, translator.components.deleteClient.deletePrompt)}
            </Typography>
          }
          {clients.length > 1
            ? <Typography variant='body2' sx={{ mt: 2 }}>{Utils.getTranslation(language, translator.components.deleteClient.deleteConfirmListPlural)}</Typography>
            : <Typography variant='body2' sx={{ mt: 2 }}>{Utils.getTranslation(language, translator.components.deleteClient.deleteConfirmList)}</Typography>
          }
          {clients.map((client, index) => (
            <Typography key={index} variant="body2" sx={{ pl: 2 }}>- {client.name}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {Utils.getTranslation(language, translator.general.cancel)}
          </Button>
          <Button variant="contained" color="error" onClick={deleteClients} autoFocus>
            {Utils.getTranslation(language, translator.general.delete)}
          </Button>
        </DialogActions>
      </Dialog>
      <Spinner show={loading}/>
    </>
  );
};

export default DeleteClientConfirmation;
