import {
  Box, Button, IconButton, LinearProgress, Tooltip, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { CreditCard, Star } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PricingPlan from '../models/enums/PricingPlans';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import PlansRestrictions from '../models/enums/PlansRestrictions';
import AlertUtils from '../utils/AlertUtil';
import Spinner from './Spinner';
import { functions } from '../FirebaseConfig';
import Utils from '../utils/Utils';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';

const PlanWidget: React.FC = () => {
  const dispatch = useAppDispatch();

  const { user, clients } = useAppSelector((state) => state.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const navigate = useNavigate();

  const [clientsUsage, setClientsUsage] = useState(0);
  const [clientsUsageColor, setClientsUsageColor] = useState('secondary') as any;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (clients) {
        let tempClientUsage = (user.documentsCounter.clientsCounter / PlansRestrictions.FREEMIUM.clientsCount) * 100;
        if (tempClientUsage > 100) tempClientUsage = 100;
        setClientsUsage(tempClientUsage);
        if (tempClientUsage < 70) {
          setClientsUsageColor('secondary');
        } else if (tempClientUsage >= 70 && tempClientUsage < 90) {
          setClientsUsageColor('warning');
        } else if (tempClientUsage >= 90) {
          setClientsUsageColor('error');
        }
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  }, [clients]);

  const displayPlan = () => {
    if (user.plan === PricingPlan.FREEMIUM) {
      return Utils.getTranslation(language, translator.general.gratuit);
    } if (user.plan === PricingPlan.PREMIUM) {
      return Utils.getTranslation(language, translator.general.pro);
    }
    return '';
  };

  const displayClientsLimit = () => {
    if (user.plan === PricingPlan.FREEMIUM) {
      return (
        <Box>
          <Typography
            color="text.secondary"
            variant="body2"
          >
            {Utils.getTranslation(language, translator.components.planWidget.limiteClients)} - ({user.documentsCounter.clientsCounter}/{PlansRestrictions.FREEMIUM.clientsCount})
          </Typography>
          <Box sx={{
            marginTop: '5px',
          }}>
            <LinearProgress color={clientsUsageColor} variant="determinate" value={clientsUsage} />
          </Box>
        </Box>
      );
    } if (user.plan === PricingPlan.PREMIUM) {
      return (
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {Utils.getTranslation(language, translator.components.planWidget.limiteClientsIllimite)}
        </Typography>
      );
    }
    return <></>;
  };

  const upgradePlan = async () => {
    navigate('/pricing');
  };

  const goToStripeDashboard = async () => {
    const returnUrl = process.env.REACT_APP_PUBLIC_URL;
    const customerId = user.stripe_customer;

    setLoading(true);
    try {
      const createCustomerPortalSession = httpsCallable(functions, 'stripe-createStripePortal');
      const response = await createCustomerPortalSession({
        userId: user.id, customerId, returnUrl,
      }) as any;

      if (response.data && response.data.url) {
        console.log(response.data.url);
        window.location.href = response.data.url;
        setLoading(false);
      } else {
        throw Error('Couldn\'t get stripe portal url');
      }
    } catch (e) {
      setLoading(false);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  };

  return (
    <>
      {
        user && clients
          ? <Box sx={{
            marginTop: '8px',
          }}>
            <Box sx={{ marginBottom: '20px' }}>
              <Typography
                variant="overline"
                sx={{
                  marginTop: '5px',
                }}
              >
                {Utils.getTranslation(language, translator.components.planWidget.planCourant)} - {displayPlan()}
              </Typography>
              {user.plan === PricingPlan.FREEMIUM && <Button onClick={upgradePlan} endIcon={ <Star fontSize='small'/>} variant='contained' color='secondary'>
                {Utils.getTranslation(language, translator.pages.upgrade.upgrade)}
              </Button>}
              {user.plan === PricingPlan.PREMIUM && user.stripe_customer && <Tooltip
                title={Utils.getTranslation(language, translator.components.planWidget.settings)}
              >
                <IconButton
                  onClick={() => goToStripeDashboard()}>
                  <CreditCard />
                </IconButton>
              </Tooltip>}
            </Box>
            {displayClientsLimit()}
          </Box>
          : null
      }
      <Spinner show={loading}/>
    </>

  );
};

export default PlanWidget;
