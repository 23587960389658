import ClientTypes from './enums/ClientTypes';

const clientTypeOptions = [
  {
    value: ClientTypes.RESERVE,
    label: {
      fr: 'Banque de contacts',
      en: 'Reserve list',
      es: 'Lista de reserva',
      pt: 'Lista de reserva',
    },
  },
  {
    value: ClientTypes.PROSPECT,
    label: {
      fr: 'Prospect',
      en: 'Prospect',
      es: 'Prospecto',
      pt: 'Potencial cliente',
    },
  },
  {
    value: ClientTypes.CLIENT,
    label: {
      fr: 'Client',
      en: 'Client',
      es: 'Cliente',
      pt: 'Cliente',
    },
  },
  {
    value: ClientTypes.PROPOSITION,
    label: {
      fr: 'Proposition',
      en: 'Proposal',
      es: 'Propuesta',
      pt: 'Proposta',
    },
  },
  {
    value: ClientTypes.PEU_INTERESSANT,
    label: {
      fr: 'Contacts en attente',
      en: 'Contacts on hold',
      es: 'Contactos en espera',
      pt: 'Contatos em espera',
    },
  },
];

export default clientTypeOptions;
