import {
  Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'formik-mui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import Spinner from '../components/Spinner';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import translator from '../theme/translator.json';
import Utils from '../utils/Utils';
import User from '../models/User';
import UserService from '../services/UserService';
import AlertUtils from '../utils/AlertUtil';
import { auth } from '../FirebaseConfig';
import PromptForCredentials from '../components/PromptForCredentials';
import VisibilityState from '../models/enums/VisibilityState';
import LanguageSelector from '../components/languageSelector/LanguageSelector';

const Settings: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPromptCredential, setShowPromptCredential] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handlePromptCredentialAlert = (show: boolean) => {
    if (show) setShowPromptCredential(true);
    else setShowPromptCredential(false);
  };

  const updateUser = async (values: any) => {
    try {
      setLoading(true);
      const newName = values.name;
      const newPhone = values.phone;
      const newUser = {
        ...user,
      } as User;
      newUser.name = newName;
      newUser.phone = newPhone;

      await UserService.update(user.id, newUser);
      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      setLoading(false);
      console.error(e);
      AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  };

  const changeEmail = async (values: any) => {
    try {
      setLoading(true);
      const { newEmail } = values;
      const newUser = {
        ...user,
      } as User;
      newUser.email = newEmail;

      if (auth.currentUser) {
        await UserService.updateEmail(auth.currentUser, newEmail);
        await UserService.update(user.id, newUser);
      }

      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      // Change email needs reauthantification if credentials have timed out
      if (e.code === 'auth/requires-recent-login') {
        setLoading(false);
        setShowPromptCredential(true);
      } else {
        setLoading(false);
        console.error(e);
        AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
      }
    }
  };

  const changePassword = async (values: any) => {
    try {
      setLoading(true);
      const { password } = values;
      if (auth.currentUser) {
        await UserService.updatePassword(auth.currentUser, password);
      }

      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      // Change password needs reauthantification if credentials have timed out
      if (e.code === 'auth/requires-recent-login') {
        setLoading(false);
        setShowPromptCredential(true);
      } else {
        setLoading(false);
        console.error(e);
        AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
      }
    }
  };

  const deleteAccount = async () => {
    try {
      setLoading(true);
      if (auth.currentUser) {
        await UserService.update(auth.currentUser.uid, { visibility: VisibilityState.HIDDEN });
        UserService.deleteAccount(auth.currentUser, auth.currentUser.uid, user.stripe_sub_id);
      } else {
        throw Error('Delete account - Firebase user undefined');
      }
      setShowDeleteDialog(false);
      navigate('/signup');
      setLoading(false);
      await AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.updateCompleted), dispatch);
    } catch (e: any) {
      // Delete account needs reauthantification if credentials have timed out
      if (e.code === 'auth/requires-recent-login') {
        setLoading(false);
        setShowPromptCredential(true);
      } else {
        setLoading(false);
        console.error(e);
        AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
      }
    }
  };

  return (
    <>
      {user && <Layout title={Utils.getTranslation(language, translator.pages.settings.title)}>
        <Container
          maxWidth="lg"
          sx={{
            mt: 5,
          }}
        >
          <Card>
            <CardHeader
              title={Utils.getTranslation(language, translator.pages.settings.changeLanguage)}
            />
            <Divider />
            <CardContent>
              <LanguageSelector />
            </CardContent>
          </Card>
        </Container>
        <Container
          maxWidth="lg"
          sx={{
            mt: 3,
          }}
        >
          {/**
           *  Personal information form
           */}
          <Formik
            initialValues={{
              name: user?.name,
              phone: user?.phone,
            }}
            validationSchema={yup.object({
              name: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
              phone: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
            })}
            onSubmit={(values, { setSubmitting }) => {
              updateUser(values);
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form>
                <Card>
                  <CardHeader
                    title={Utils.getTranslation(language, translator.pages.settings.information.title)}
                    subheader={Utils.getTranslation(language, translator.pages.settings.information.subheader)}
                  />
                  <Divider />
                  <CardContent>
                    <Field
                      component={TextField}
                      name="name"
                      type="text"
                      label={Utils.getTranslation(language, translator.pages.settings.information.inputs.nameLabel)}
                      margin='normal'
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="phone"
                      type="text"
                      label={Utils.getTranslation(language, translator.pages.signup.phone)}
                      margin='normal'
                      fullWidth
                    />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={formikProps.isSubmitting}
                    >
                      {Utils.getTranslation(language, translator.pages.settings.information.submit)}
                    </Button>
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        </Container>

        {/**
           *  Personal information form
           */}
        <Container
          maxWidth="lg"
          sx={{
            marginTop: '20px',
          }}
        >
          <Formik
            initialValues={{
              newEmail: '',
            }}
            validationSchema={yup.object({
              newEmail: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              changeEmail(values);
              setSubmitting(false);
              resetForm();
            }}
          >
            {(formikProps) => (
              <Form>
                <Card>
                  <CardHeader
                    title={Utils.getTranslation(language, translator.pages.settings.changeEmail.title)}
                    subheader={user?.email}
                  />
                  <Divider />
                  <CardContent>
                    <Field
                      component={TextField}
                      name="newEmail"
                      type="text"
                      label={Utils.getTranslation(language, translator.pages.settings.changeEmail.inputs.email)}
                      margin='normal'
                      fullWidth
                    />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={formikProps.isSubmitting}
                    >
                      {Utils.getTranslation(language, translator.pages.settings.changeEmail.submit)}
                    </Button>
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        </Container>

        {/**
           *  Password form
           */}
        <Container
          maxWidth="lg"
          sx={{
            marginTop: '20px',
          }}
        >
          <Formik
            initialValues={{
              password: '',
              passwordConfirmation: '',
            }}
            validationSchema={yup.object({
              password: yup.string().min(6).required(Utils.getTranslation(language, translator.formMessages.requiredField)),
              passwordConfirmation: yup.string()
                .required(Utils.getTranslation(language, translator.formMessages.requiredField))
                .oneOf([yup.ref('password'), null], Utils.getTranslation(language, translator.formMessages.passwordsMustMatch)),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              changePassword(values);
              setSubmitting(false);
              resetForm();
            }}
          >
            {(formikProps) => (
              <Form>
                <Card>
                  <CardHeader
                    title={Utils.getTranslation(language, translator.pages.settings.changePassword.title)}
                  />
                  <Divider />
                  <CardContent>
                    <Field
                      component={TextField}
                      name="password"
                      type="password"
                      label={Utils.getTranslation(language, translator.pages.settings.changePassword.inputs.password)}
                      margin='normal'
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="passwordConfirmation"
                      type="password"
                      label={Utils.getTranslation(language, translator.pages.settings.changePassword.inputs.passwordConfirmation)}
                      margin='normal'
                      fullWidth
                    />
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2,
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {Utils.getTranslation(language, translator.pages.settings.changePassword.submit)}
                    </Button>
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>
        </Container>
        {/**
           *  Deleting account form
           */}
        <Container
          maxWidth="lg"
          sx={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <Card sx={{
            backgroundColor: '#D09797',
            border: '2px solid red',
          }}>
            <CardHeader
              title={
                <Typography variant='h5' sx={{ color: 'white' }}>
                  {Utils.getTranslation(language, translator.pages.settings.deleteAccount.title)}
                </Typography>
              }
              subheader={
                <Typography sx={{ color: 'white' }}>
                  {Utils.getTranslation(language, translator.pages.settings.deleteAccount.subheader)}
                </Typography>
              }
            />
            <CardContent>
              <Button
                variant='contained'
                color="error"
                onClick={() => setShowDeleteDialog(true)}
              >
                {Utils.getTranslation(language, translator.pages.settings.deleteAccount.submit)}
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Layout>}
      <Spinner show={loading}/>
      <PromptForCredentials show={showPromptCredential} setShow={handlePromptCredentialAlert} />
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Utils.getTranslation(language, translator.pages.settings.deleteAccount.promptTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Utils.getTranslation(language, translator.pages.settings.deleteAccount.promptDescription)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>{Utils.getTranslation(language, translator.general.cancel)}</Button>
          <Button onClick={() => deleteAccount()} autoFocus>
            {Utils.getTranslation(language, translator.pages.settings.deleteAccount.submit)}
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default Settings;
