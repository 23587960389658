/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ClientType from '../../models/ClientType';

const initialState = {
  clientPopupOpen: false,
  orderedClientsList: [] as ClientType[],
  clientOpenId: '',
  navDisabled: false,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientPopupOpen(state, action: PayloadAction<boolean>) {
      state.clientPopupOpen = action.payload;
    },
    setOrderedClientsList(state, action: PayloadAction<ClientType[]>) {
      state.orderedClientsList = action.payload;
    },
    setClientOpenId(state, action: PayloadAction<string>) {
      state.clientOpenId = action.payload;
    },
    setNavDisabled(state, action: PayloadAction<boolean>) {
      state.navDisabled = action.payload;
    },
  },
});

export const {
  setClientPopupOpen,
  setOrderedClientsList,
  setClientOpenId,
  setNavDisabled,
} = clientSlice.actions;

export default clientSlice;
