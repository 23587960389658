import '../theme/css/global.css';
import {
  AppBar,
  Autocomplete,
  Box,
  Container,
  Dialog,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  Button,
} from '@mui/material';

import {
  forwardRef, useState,
} from 'react';
import {
  CloseOutlined, FormatListBulleted, Groups3, Person, Star,
} from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import {
  Form,
  Formik,
} from 'formik';
import * as yup from 'yup';
import { FirebaseError } from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ClientService from '../services/ClientService';
import { useAppDispatch, useAppSelector } from '../store/Hooks';
import User from '../models/User';
import AlertUtils from '../utils/AlertUtil';
import Spinner from './Spinner';
import PricingPlan from '../models/enums/PricingPlans';
import PlansRestrictions from '../models/enums/PlansRestrictions';
import UserService from '../services/UserService';
import ErrorCauses from '../models/enums/ErrorCauses';
import ClientTypes from '../models/enums/ClientTypes';
import clientTypeOptions from '../models/ClientTypeOptions';
import {
  setClientOpenId, setClientPopupOpen, setNavDisabled, setOrderedClientsList,
} from '../store/slices/ClientSlice';
import SuiviDateInput from './SuiviDateInput';
import ClientType from '../models/ClientType';
import VisibilityState from '../models/enums/VisibilityState';
import Utils from '../utils/Utils';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';

type Props = {
  open: boolean,
  onClose: () => void
};

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const initialValues = {
  clientName: '',
  interlocuteur: '',
  phone: '',
  email: '',
  tags: [],
  notes: '',
  type: ClientTypes.CLIENT,
  date: '',
  customDate: dayjs().unix() * 1000,
  priority: 99,
};

const AddClientPopup: React.FC<Props> = ({ open, onClose }) => {
  const user = useAppSelector((state) => state.user.user) as User;
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const muiTheme = useTheme();
  const fullScreen = useMediaQuery(muiTheme.breakpoints.down('md'));

  // TODO - proper phone validation
  const validationSchema = yup.object({
    clientName: yup
      .string()
      .required(Utils.getTranslation(language, translator.formMessages.requiredField)),
    interlocuteur: yup
      .string(),
    phone: yup
      .string(),
    email: yup
      .string()
      .email(Utils.getTranslation(language, translator.formMessages.invalidEmail)),
    notes: yup
      .string(),
  });

  const upgradePlan = () => {
    navigate('/pricing');
  };

  const renderClientTypeIcon = (value: ClientTypes) => {
    const option = clientTypeOptions.find((o) => o.value === value);
    switch (value) {
      case ClientTypes.RESERVE:
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
          }}>
            <FormatListBulleted fontSize="small" />
            <Typography>{option?.label[language]}</Typography>
          </Box>
        );
      case ClientTypes.PROSPECT:
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
          }}>
            <Groups3 fontSize="small" />
            <Typography>{option?.label[language]}</Typography>
          </Box>
        );
      case ClientTypes.CLIENT:
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
          }}>
            <Person fontSize="small" />
            <Typography>{option?.label[language]}</Typography>
          </Box>
        );
      case ClientTypes.PROPOSITION:
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
          }}>
            <Star fontSize="small" />
            <Typography>{option?.label[language]}</Typography>
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          onClose();
        }}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {Utils.getTranslation(language, translator.components.nav.addClientContact)}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                onClose();
              }}
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container sx={{ py: 3 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                setLoading(true);
                if (user?.plan === PricingPlan.FREEMIUM && user?.documentsCounter.clientsCounter >= PlansRestrictions.FREEMIUM.clientsCount) {
                  throw new Error(Utils.getTranslation(language, translator.errorMessages.plan.freeTierClientsLimited, {
                    numberClients: PlansRestrictions.FREEMIUM.clientsCount,
                  }), { cause: ErrorCauses.INSUFFICIENT_PLAN });
                }

                let newSuiviDate = null;
                if (values.date) newSuiviDate = (values.date === 'custom') ? Timestamp.fromDate(new Date(values.customDate)) : Timestamp.fromDate(new Date(values.date));

                const newClient = {
                  id: '',
                  name: values.clientName,
                  interlocuteur: values.interlocuteur,
                  phone: values.phone,
                  email: values.email,
                  tags: values.tags,
                  notes: values.notes,
                  type: values.type || ClientTypes.CLIENT,
                  visibility: VisibilityState.VISIBLE,
                  nextSuivi: newSuiviDate,
                  priority: values.priority,
                } as ClientType;

                const client = await ClientService.create(
                  user.id,
                  newClient,
                );

                const documentCounterTemp = {
                  ...user.documentsCounter,
                  clientsCounter: user.documentsCounter.clientsCounter + 1,
                };

                await UserService.update(user.id, { documentsCounter: documentCounterTemp });

                AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.clientSaved), dispatch);

                if (client) {
                  dispatch(setClientOpenId(client.id));
                  dispatch(setOrderedClientsList([client]));
                  dispatch(setClientPopupOpen(true));
                  dispatch(setNavDisabled(true));
                }

                resetForm();
                onClose();
                setLoading(false);
              } catch (e) {
                setLoading(false);
                if (e instanceof Error) {
                  if (e.cause === ErrorCauses.INSUFFICIENT_PLAN) {
                    AlertUtils.createErrorAlert(e.message, dispatch);
                  }
                  if (e instanceof FirebaseError) {
                    if (e.code === 'permission-denied') {
                      const message = Utils.getTranslation(language, translator.errorMessages.plan.freeTierClientsLimited, {
                        numberClients: PlansRestrictions.FREEMIUM.clientsCount,
                      });
                      AlertUtils.createErrorAlert(message, dispatch);
                    }
                  }
                } else {
                  throw e; // re-throw the error unchanged
                }
              }
            }}
          >
            {(formikProps) => (
              <Form>
                {
                  user?.plan === PricingPlan.FREEMIUM
                && <Button onClick={upgradePlan} endIcon={ <Star fontSize='small'/>} variant='contained' color='secondary'>
                  {Utils.getTranslation(language, translator.pages.upgrade.upgrade)}
                </Button>
                }
                <TextField
                  label={Utils.getTranslation(language, translator.formLabels.clientName)}
                  variant="outlined"
                  fullWidth
                  sx={{ my: 2 }}
                  name="clientName"
                  value={formikProps.values.clientName}
                  onChange={formikProps.handleChange}
                  error={formikProps.touched.clientName && Boolean(formikProps.errors.clientName)}
                  helperText={formikProps.touched.clientName && formikProps.errors.clientName}
                />
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '20px',
                  flexWrap: 'wrap',
                }}>
                  <Box>
                    <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>{Utils.getTranslation(language, translator.formLabels.contactType)}</Typography>
                    <FormControl sx={{ minWidth: '180px' }}>
                      <Select
                        value={formikProps.values.type}
                        name='type'
                        onChange={formikProps.handleChange}
                        size="small"
                        displayEmpty
                        renderValue={(value: any) => (
                          renderClientTypeIcon(value)
                        )}
                      >
                        {clientTypeOptions.map((option, index) => {
                          if (option.value === ClientTypes.PROPOSITION
                            || option.value === ClientTypes.RESERVE
                            || option.value === ClientTypes.PROSPECT
                            || option.value === ClientTypes.CLIENT) {
                            return (<MenuItem
                              key={index}
                              value={option?.value}
                            >
                              {renderClientTypeIcon(option?.value)}
                            </MenuItem>);
                          }
                          return <div key={index}></div>;
                        })};
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>{Utils.getTranslation(language, translator.formLabels.priority)}</Typography>
                    <FormControl sx={{ minWidth: '180px' }}>
                      <Select
                        value={formikProps.values.priority}
                        name='priority'
                        onChange={formikProps.handleChange}
                        size="small"
                      >
                        <MenuItem value={99}>{Utils.getTranslation(language, translator.general.aucune)}</MenuItem>
                        {
                          [1, 2, 3].map((option : any, index: number) => (
                            <MenuItem key={index} value={option}>{option}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>{Utils.getTranslation(language, translator.components.ficheClient.dateProchainSuivi)}</Typography>
                <SuiviDateInput showNull/>
                <Box sx={{
                  display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, my: 2, mt: 3,
                }}>
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.interlocuteur)}
                    variant="outlined"
                    sx={{ minWidth: 200, width: '48%' }}
                    name="interlocuteur"
                    value={formikProps.values.interlocuteur}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.interlocuteur && Boolean(formikProps.errors.interlocuteur)}
                    helperText={formikProps.touched.interlocuteur && formikProps.errors.interlocuteur}
                  />
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.telephone)}
                    variant="outlined"
                    sx={{ minWidth: 200, width: '48%' }}
                    name="phone"
                    value={formikProps.values.phone}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.phone && Boolean(formikProps.errors.phone)}
                    helperText={formikProps.touched.phone && formikProps.errors.phone}
                  />
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.courriel)}
                    variant="outlined"
                    sx={{ minWidth: 200, width: '48%' }}
                    name="email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                    helperText={formikProps.touched.email && formikProps.errors.email}
                  />
                  { user
                    && <Autocomplete
                      multiple
                      id="tags"
                      options={(user.clientTags) ? user.clientTags : []}
                      selectOnFocus
                      freeSolo
                      value={formikProps.values.tags}
                      sx={{ minWidth: 200, width: '48%' }}
                      onChange={(e, value) => formikProps.setFieldValue('tags', (value) || [])}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={Utils.getTranslation(language, translator.formLabels.categories)}
                        />
                      )}
                    />
                  }
                </Box>
                <TextField
                  label={Utils.getTranslation(language, translator.formLabels.Notes)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  sx={{ my: 2 }}
                  name="notes"
                  value={formikProps.values.notes}
                  onChange={formikProps.handleChange}
                  error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
                  helperText={formikProps.touched.notes && formikProps.errors.notes}
                />
                <Box
                  sx={{
                    marginTop: '15px',
                  }}>
                  <Button
                    disabled={(user?.plan === PricingPlan.FREEMIUM && user?.documentsCounter.clientsCounter >= PlansRestrictions.FREEMIUM.clientsCount) || formikProps.isSubmitting}
                    color="secondary" variant="contained" fullWidth type="submit">
                    {Utils.getTranslation(language, translator.general.ajouter)}
                  </Button>
                  {
                    user?.plan === PricingPlan.FREEMIUM && user?.documentsCounter.clientsCounter >= PlansRestrictions.FREEMIUM.clientsCount
                && <Box
                  sx={{
                    mt: '15px',
                  }}
                >
                  <Typography color="error" variant="body1">
                    {Utils.getTranslation(language, translator.errorMessages.plan.freeTierClientsLimited, { numberClients: PlansRestrictions.FREEMIUM.clientsCount.toString() })}
                  </Typography>
                </Box>
                  }
                </Box>
              </Form>)}
          </Formik>
        </Container>
      </Dialog>
      <Spinner show={loading}/>
    </>
  );
};

export default AddClientPopup;
