import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CampaignIcon from '@mui/icons-material/Campaign';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LabelIcon from '@mui/icons-material/Label';
import { Close, Edit } from '@mui/icons-material';
import ClientType from '../../../models/ClientType';
import Utils from '../../../utils/Utils';
import translator from '../../../theme/translator.json';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import Spinner from '../../Spinner';
import ClientService from '../../../services/ClientService';
import themeConfig from '../../../theme/ThemeConfig';
import AlertUtils from '../../../utils/AlertUtil';
import UserService from '../../../services/UserService';
import User from '../../../models/User';

type Props = {
  client: ClientType,
  handleCloseSummary: () => void,
};

const Profile: React.FC<Props> = ({ client, handleCloseSummary }) => {
  const user = useAppSelector((state) => state.user.user) as User;
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [clientCopy, setClientCopy] = useState(client);

  useEffect(() => {
    setClientCopy(client);
  }, [client]);

  const handleStartEditing = () => {
    setEditing(true);
  };

  const handleCancelEditing = () => {
    setEditing(false);
  };

  const yupValidateIsEmail = (email:string) : boolean => {
    const validationSchema = yup.object({
      email: yup.string().email(),
    });
    return validationSchema.isValidSync({
      email,
    });
  };

  return (
    clientCopy
    && <>
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        gap: '10px',
      }}>
        <Box>
          <Typography variant="h3" sx={{ '@media (max-width:565px)': { fontSize: 15 } }}><strong>{clientCopy.name}</strong></Typography>
          <Typography variant="body2">
            {Utils.getTranslation(language, translator.components.ficheClient.creationDate)} - {dayjs(clientCopy.createdDate).format('DD/MM/YYYY')}
          </Typography>
        </Box>
        {
          editing
            ? <IconButton onClick={() => handleCancelEditing()} size="small"><Close fontSize="small" /></IconButton>
            : <IconButton onClick={() => handleStartEditing()} size="small"><Edit fontSize="small" /></IconButton>
        }
      </Box>
      <Divider
        sx={{
          m: themeConfig.spacing(2, 0, 3, 0),
        }} />
      <Formik
        initialValues={{
          email: clientCopy.email,
          name: clientCopy.name,
          interlocuteur: clientCopy.interlocuteur,
          phone: clientCopy.phone,
          tags: clientCopy.tags,
          notes: clientCopy.notes,
        }}
        enableReinitialize
        validationSchema={yup.object({
          name: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
          email: yup.string()
            .email(Utils.getTranslation(language, translator.formMessages.invalidEmail)),
          interlocuteur: yup.string(),
          phone: yup.string(),
          notes: yup.string(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setLoading(true);

            if (user.id) {
              const newClient = {
                name: values.name,
                email: values.email,
                interlocuteur: values.interlocuteur,
                phone: values.phone,
                tags: values.tags,
                notes: values.notes,
              };
              setClientCopy({
                ...newClient,
                visibility: client.visibility,
                id: client.id,
              });
              await ClientService.update(user.id, client.id, newClient);
              if (values.tags) UserService.updateTags(user.id, user.clientTags, values.tags);
            }

            setEditing(false);
            setSubmitting(false);
            setLoading(false);

            AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.clientSaved), dispatch);
          } catch (e) {
            setLoading(false);
            console.error(e);
            AlertUtils.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
          }
        }}
      >
        {(formikProps) => (
          <Form>
            {
              editing
                ? <Box sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '15px',
                  justifyContent: 'flex-start',
                }}>
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.clientName)}
                    variant="outlined"
                    name="name"
                    sx={{ width: '100%', maxWidth: '200px' }}
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                    helperText={formikProps.touched.name && formikProps.errors.name}
                  />
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.interlocuteur)}
                    variant="outlined"
                    name="interlocuteur"
                    sx={{ width: '100%', maxWidth: '200px' }}
                    value={formikProps.values.interlocuteur}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.interlocuteur && Boolean(formikProps.errors.interlocuteur)}
                    helperText={formikProps.touched.interlocuteur && formikProps.errors.interlocuteur}
                  />
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.telephone)}
                    variant="outlined"
                    name="phone"
                    sx={{ width: '100%', maxWidth: '200px' }}
                    value={formikProps.values.phone}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.phone && Boolean(formikProps.errors.phone)}
                    helperText={formikProps.touched.phone && formikProps.errors.phone}
                  />
                  <TextField
                    label={Utils.getTranslation(language, translator.formLabels.courriel)}
                    variant="outlined"
                    name="email"
                    sx={{ width: '100%', maxWidth: '200px' }}
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                    helperText={formikProps.touched.email && formikProps.errors.email}
                  />
                  { user
                    && <Autocomplete
                      multiple
                      sx={{ minWidth: '200px' }}
                      id="tags"
                      options={(user.clientTags) ? user.clientTags : []}
                      selectOnFocus
                      freeSolo
                      value={formikProps.values.tags}
                      onChange={(e, value) => formikProps.setFieldValue('tags', (value) || [])}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={Utils.getTranslation(language, translator.formLabels.categories)}
                        />
                      )}
                    />
                  }

                </Box>
                : <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      width: '100%',
                      '@media (max-width:565px)': { display: 'block' },
                      '.MuiListItemIcon-root': {
                        minWidth: 0,
                        mr: 1,
                      },
                      '.MuiListItem-root': {
                        px: 0,
                      },
                    }}>
                    <ListItem onClick={() => setEditing(true)} sx={{ width: 'auto' }}>
                      <Tooltip title={Utils.getTranslation(language, translator.formLabels.interlocuteur)}>
                        <ListItemIcon>
                          <CampaignIcon color="primary"/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <Typography sx={{
                            overflowWrap: 'break-word',
                          }}>
                            {clientCopy.interlocuteur ? clientCopy.interlocuteur : '-'}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem onClick={() => setEditing(true)} sx={{ width: 'auto' }}>
                      <Tooltip title={Utils.getTranslation(language, translator.formLabels.telephone)}>
                        <ListItemIcon>
                          <LocalPhoneIcon color="primary"/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <Typography sx={{
                            overflowWrap: 'break-word',
                          }}>
                            {clientCopy.phone ? clientCopy.phone : '-'}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem onClick={() => setEditing(true)} sx={{ width: 'auto' }}>
                      <Tooltip title={Utils.getTranslation(language, translator.formLabels.courriel)}>
                        <ListItemIcon>
                          <AlternateEmailIcon color="primary"/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <Link href={clientCopy.email && yupValidateIsEmail(clientCopy.email) ? `mailto:${clientCopy.email}` : undefined} sx={{
                            overflowWrap: 'break-word',
                          }}>
                            {clientCopy.email ? clientCopy.email : '-'}
                          </Link>
                        }
                      />
                    </ListItem>
                    <ListItem onClick={() => setEditing(true)} sx={{ width: 'auto' }}>
                      <Tooltip title={Utils.getTranslation(language, translator.formLabels.categories)}>
                        <ListItemIcon>
                          <LabelIcon color="primary"/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={
                          <>
                            {
                              clientCopy.tags?.length === 0
                                ? <Typography sx={{
                                  overflowWrap: 'break-word',
                                }}>
                                -
                                </Typography>
                                : <Box sx={{
                                  display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'flex-start', width: '100%', overflowX: 'auto', maxWidth: 300,
                                }}>
                                  {

                                    clientCopy.tags?.map((tag: string, index: number) => (
                                      <Chip variant='outlined' color='secondary' size="small" key={index} label={tag} />
                                    ))
                                  }
                                </Box>
                            }
                          </>
                        }
                      />
                    </ListItem>
                  </Box>
                </>
            }
            <TextField
              sx={{ my: 2 }}
              label={Utils.getTranslation(language, translator.formLabels.description)}
              variant="outlined"
              name="notes"
              fullWidth
              multiline
              rows={4}
              value={formikProps.values.notes}
              onChange={formikProps.handleChange}
              onBlur={() => {
                formikProps.handleSubmit();
              }}
              error={formikProps.touched.notes && Boolean(formikProps.errors.notes)}
              helperText={formikProps.touched.notes && formikProps.errors.notes}
            />
            {editing && <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
                gap: '15px',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={formikProps.isSubmitting}
              >
                {Utils.getTranslation(language, translator.general.sauvegarder)}
              </Button>
            </Box>}
          </Form>
        )}
      </Formik>
      <Spinner show={loading}/>
    </>
  );
};

export default Profile;
