import { responsiveFontSizes } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';

// eslint-disable-next-line import/no-mutable-exports
let themeConfig = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#16213E',
      light: '#007591',
    },
    secondary: {
      main: '#F55C47',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Poppins',
      'Arial',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2.3rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.7rem',
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 200,
    },
  },
});

themeConfig = responsiveFontSizes(themeConfig);

export default themeConfig;
