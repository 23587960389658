import {
  collection, doc, getDoc, setDoc, Timestamp, updateDoc,
} from 'firebase/firestore';

import { db } from '../FirebaseConfig';
import VisibilityState from '../models/enums/VisibilityState';
import ClientType from '../models/ClientType';

class ClientService {
  static create = async (userId:string, client:ClientType) : Promise<ClientType | undefined> => {
    const userDoc = await getDoc(doc(db, 'Users', userId));
    // TODO - Should throw an error
    if (!userDoc.exists) return undefined;

    const userDocData = userDoc.data();
    // TODO - Should throw an error
    if (!userDocData) return undefined;

    const clientDocRef = await doc(collection(db, 'Users', userId, 'Clients'));

    client.id = clientDocRef.id;

    await setDoc(clientDocRef, client);

    if (client.tags) {
      const tempTags = [...userDocData.clientTags];
      client.tags.forEach((tag:string) => {
        if (!userDocData.clientTags.includes(tag)) tempTags.push(tag);
      });

      await updateDoc(userDoc.ref, {
        clientTags: tempTags,
      });
    }

    return client;
  };

  static createFast = async (
    userId: string,
    name: string,
    interlocuteur: string,
    phone: string,
    email: string,
    notes: string,
    type: string,
  ) => {
    const newClient = doc(collection(db, 'Users', userId, 'Clients'));
    return setDoc(newClient, {
      id: newClient.id,
      email,
      name,
      createdDate: new Date(),
      interlocuteur,
      phone,
      tags: [],
      notes,
      type,
      visibility: VisibilityState.VISIBLE,
      priority: 99,
    });
  };

  static update = async (userId: string, clientId: string, client: any) => {
    updateDoc(doc(db, 'Users', userId, 'Clients', clientId), { ...client });
  };

  static updateNextSuiviDate = async (userId:string, clientId:string, nextSuivi: Timestamp | null) => {
    updateDoc(doc(db, 'Users', userId, 'Clients', clientId), { nextSuivi });
  };
}

export default ClientService;
