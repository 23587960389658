import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import * as yup from 'yup';

import {
  Box, IconButton, InputAdornment,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TextField } from 'formik-mui';
import UserService from '../../services/UserService';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import EmailService from '../../services/EmailService';
import { auth } from '../../FirebaseConfig';
import Spinner from '../../components/Spinner';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import translator from '../../theme/translator.json';
import Utils from '../../utils/Utils';
import AlertsContainer from '../../components/AlertsContainer';
import ErrorService from '../../services/ErrorService';
import LanguageSelector from '../../components/languageSelector/LanguageSelector';

const Signup: React.FC = () => {
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reference, setReference] = useState('');

  useEffect(() => {
    try {
      if (!auth.currentUser) {
        const refParam = searchParams.get('ref');
        if (refParam) {
          setReference(refParam);
        }
      }
    } catch (e: any) {
      ErrorService.handleError(e, dispatch, language);
    }
  }, [searchParams.get('ref')]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const createAccount = async (values: any) => {
    try {
      setLoading(true);
      const firebaseUser = await UserService.createAccount(values.email, values.password);
      await UserService.create(firebaseUser.user.uid, values.name, values.email, values.phone, language, reference);

      if (auth.currentUser) await EmailService.sendAccountConfirmation(auth.currentUser);

      setLoading(false);
      navigate('/?from=accountCreated');
    } catch (e: any) {
      if (auth.currentUser) {
        UserService.delete(auth.currentUser.uid);
        UserService.deleteAccount(auth.currentUser, auth.currentUser.uid, '');
      }
      setLoading(false);
      console.error(e);
      ErrorService.handleFirebaseError(e, dispatch, language);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Box
          sx={{
            padding: 3,
            pt: 6,
            background: 'white',
            position: 'relative',
          }}>
          <Box sx={{
            position: 'absolute', width: '100px', right: 24, top: 24,
          }}>
            <LanguageSelector />
          </Box>
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              phone: '',
              passwordConfirmation: '',
            }}
            validationSchema={yup.object({
              name: yup.string()
                .required(Utils.getTranslation(language, translator.formMessages.requiredField)),
              email: yup.string()
                .email(Utils.getTranslation(language, translator.formMessages.invalidEmail))
                .required(Utils.getTranslation(language, translator.formMessages.requiredField)),
              phone: yup.string()
                .required(Utils.getTranslation(language, translator.formMessages.requiredField)),
              password: yup.string().min(6).required(Utils.getTranslation(language, translator.formMessages.requiredField)),
              passwordConfirmation: yup.string()
                .required(Utils.getTranslation(language, translator.formMessages.requiredField))
                .oneOf([yup.ref('password'), null], Utils.getTranslation(language, translator.formMessages.passwordsMustMatch)),
            })}
            onSubmit={(values, { setSubmitting }) => {
              createAccount(values);
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: '500px',
                }}
              >
                <Avatar sx={{ m: '0 auto 15px auto', bgcolor: 'secondary.main', color: 'info.main' }}>
                  <LockOutlinedIcon htmlColor="#fff" />
                </Avatar>
                <Typography variant="h3">
                  {Utils.getTranslation(language, translator.pages.signup.title)}
                </Typography>
                <Form className="login__form-container">
                  <Field
                    component={TextField}
                    name="name"
                    type="text"
                    label={Utils.getTranslation(language, translator.pages.signup.fullName)}
                    margin='normal'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="phone"
                    type="text"
                    label={Utils.getTranslation(language, translator.pages.signup.phone)}
                    margin='normal'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label={Utils.getTranslation(language, translator.pages.signup.email)}
                    margin='normal'
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="password"
                    margin='normal'
                    type={showPassword ? 'text' : 'password'}
                    label={Utils.getTranslation(language, translator.pages.signup.password)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={(e: any) => e.preventDefault()}
                            color="secondary"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>

                      ),
                    }}
                  />
                  <Field
                    component={TextField}
                    name="passwordConfirmation"
                    margin='normal'
                    type={showPassword ? 'text' : 'password'}
                    label={Utils.getTranslation(language, translator.pages.signup.passwordConfirmation)}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={(e: any) => e.preventDefault()}
                            color="secondary"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={formikProps.isSubmitting}
                  >
                    {Utils.getTranslation(language, translator.pages.signup.submit)}
                  </Button>
                </Form>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    width: '100%',
                  }}
                >
                  <Link to={searchParams.get('lang')
                    ? `/login?lang=${searchParams.get('lang')}`
                    : `/login?lang=${SupportedLanguages.DEFAULT}`} style={{ color: 'black', fontSize: 14 }}>
                    {Utils.getTranslation(language, translator.pages.signup.toLogin)}
                  </Link>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
      <Spinner show={loading}/>
      <AlertsContainer/>
    </Container>
  );
};

export default Signup;
