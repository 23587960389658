import {
  Button,
  DialogContent, Box, DialogTitle, Typography,
} from '@mui/material';
import { useState } from 'react';
import AddClientPopup from '../../AddClientPopup';
import ImportPopup from '../../importPopup/ImportPopup';
import Utils from '../../../utils/Utils';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import translator from '../../../theme/translator.json';
import { useAppSelector } from '../../../store/Hooks';

const Step2: React.FC = () => {
  const [addClientDialogOpen, setAddClientDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>{Utils.getTranslation(language, translator.components.tutorialPopup.step2Title)}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box sx={{
          display: 'flex', gap: '15px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setAddClientDialogOpen(true)}
          >{Utils.getTranslation(language, translator.components.tutorialPopup.createClientBtn)}</Button>
          <Typography variant='body1'>{Utils.getTranslation(language, translator.general.or)}</Typography>
          <Button
            variant="outlined"
            color='secondary'
            onClick={() => setImportDialogOpen(true)}
          >{Utils.getTranslation(language, translator.components.tutorialPopup.importBtn)}</Button>
        </Box>
        <AddClientPopup open={addClientDialogOpen} onClose={() => setAddClientDialogOpen(false)} />
        <ImportPopup open={importDialogOpen} onClose={() => setImportDialogOpen(false)} />
      </DialogContent>
    </>
  );
};

export default Step2;
