import { forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog, DialogContent, DialogTitle, MobileStepper, Slide, useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Step1 from './components/step1';
import Step2 from './components/step2';
import HeaderBg from '../../theme/assets/header-bg.png';
import Utils from '../../utils/Utils';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import translator from '../../theme/translator.json';
import { useAppSelector } from '../../store/Hooks';

type Props = {
    open: boolean,
    onClose: () => void
  };

const Transition = forwardRef((
  props: TransitionProps & {
      children: React.ReactElement;
    },
  ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const TutorialPopup: React.FC<Props> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 2;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      onClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <Step1/>;
      case 1:
        return <Step2/>;
      default:
        return <></>;
    }
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth='lg'
      open={open}
      onClose={() => {
        onClose();
      }}
      TransitionComponent={Transition}
    >
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'relative', zIndex: 1, padding: '20px 0' }}>
          <DialogTitle sx={{ textAlign: 'center', color: '#fff', fontSize: '26px !important' }}>
            <strong>{Utils.getTranslation(language, translator.components.tutorialPopup.title)}</strong>
          </DialogTitle>
        </Box>
        <img style={{
          position: 'absolute', top: 0, zIndex: 0, width: '100%', height: '100%',
        }} src={HeaderBg}></img>
      </Box>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box>
          {renderStep()}
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
            >
              {
                activeStep === maxSteps - 1
                  ? Utils.getTranslation(language, translator.general.close)
                  : Utils.getTranslation(language, translator.general.suivant)
              }
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {Utils.getTranslation(language, translator.general.precedent)}
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default TutorialPopup;
