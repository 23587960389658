import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import * as yup from 'yup';

import {
  Box, Button, Container, FormControlLabel, IconButton, InputAdornment,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Switch, TextField } from 'formik-mui';
import { browserLocalPersistence, browserSessionPersistence, setPersistence } from 'firebase/auth';
import UserService from '../../services/UserService';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import { auth } from '../../FirebaseConfig';
import Spinner from '../../components/Spinner';
import Utils from '../../utils/Utils';
import translator from '../../theme/translator.json';
import AlertsContainer from '../../components/AlertsContainer';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import ErrorService from '../../services/ErrorService';
import LanguageSelector from '../../components/languageSelector/LanguageSelector';

const Login: React.FC = () => {
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const login = async (values: any) => {
    try {
      setLoading(true);

      // Closing the window would not clear any existing state
      if (values.rememberMe) setPersistence(auth, browserLocalPersistence);

      // Closing the window would clear any existing state
      else setPersistence(auth, browserSessionPersistence);
      await UserService.login(values.email, values.password);

      setLoading(false);
      navigate('/');
    } catch (e: any) {
      setLoading(false);
      console.error(e);
      ErrorService.handleFirebaseError(e, dispatch, language);
    }
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Box
            sx={{
              padding: 3,
              pt: 6,
              background: 'white',
              position: 'relative',
            }}>
            <Box sx={{
              position: 'absolute', width: '100px', right: 24, top: 24,
            }}>
              <LanguageSelector />
            </Box>
            <Formik
              initialValues={{
                email: '',
                password: '',
                rememberMe: true,
              }}
              validationSchema={yup.object({
                email: yup.string()
                  .email(Utils.getTranslation(language, translator.formMessages.invalidEmail))
                  .required(Utils.getTranslation(language, translator.formMessages.requiredField)),
                password: yup.string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
                stayConnected: yup.boolean(),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false);
                await login(values);
              }}
            >
              {(formikProps) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '500px',
                  }}
                >
                  <Avatar sx={{ m: '0 auto 15px auto', bgcolor: 'secondary.main', color: 'info.main' }}>
                    <LockOutlinedIcon htmlColor="#fff"/>
                  </Avatar>
                  <Typography variant="h3">
                    {Utils.getTranslation(language, translator.pages.login.title)}
                  </Typography>
                  <Form>
                    <Field
                      component={TextField}
                      name="email"
                      type="email"
                      color='primary'
                      label={Utils.getTranslation(language, translator.pages.login.email)}
                      margin='normal'
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      name="password"
                      margin='normal'
                      color='primary'
                      type={showPassword ? 'text' : 'password'}
                      label={Utils.getTranslation(language, translator.pages.login.password)}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={(e: any) => e.preventDefault()}
                              color="secondary"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Field component={Switch} color="secondary" type="checkbox" name="rememberMe" />
                      }
                      label={Utils.getTranslation(language, translator.pages.login.rememberMe)}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={formikProps.isSubmitting}
                    >
                      {Utils.getTranslation(language, translator.pages.login.submit)}
                    </Button>
                  </Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      mt: 2,
                    }}
                  >
                    <Link to={searchParams.get('lang')
                      ? `/forgot-password?lang=${searchParams.get('lang')}`
                      : `/forgot-password?lang=${SupportedLanguages.DEFAULT}`}
                    style={{ color: 'black', fontSize: 14 }}>
                      {Utils.getTranslation(language, translator.pages.login.toForgotPassword)}
                    </Link>

                    <Link to={searchParams.get('lang')
                      ? `/signup?lang=${searchParams.get('lang')}`
                      : `/signup?lang=${SupportedLanguages.DEFAULT}`}
                    style={{ color: 'black', fontSize: 14 }}>
                      {Utils.getTranslation(language, translator.pages.login.toSignup)}
                    </Link>
                  </Box>
                </Box>
              )}
            </Formik>
            <Spinner show={loading}/>
            <AlertsContainer/>
          </Box>
        </Box>
      </Container>
      <Spinner show={loading}/>
    </>
  );
};

export default Login;
