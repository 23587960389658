import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import * as yup from 'yup';

import {
  Box,
} from '@mui/material';
import { TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/Hooks';
import EmailService from '../../services/EmailService';
import Spinner from '../../components/Spinner';
import translator from '../../theme/translator.json';
import Utils from '../../utils/Utils';
import AlertsContainer from '../../components/AlertsContainer';
import SupportedLanguages from '../../models/enums/SupportedLanguages';
import AlertUtil from '../../utils/AlertUtil';
import LanguageSelector from '../../components/languageSelector/LanguageSelector';

const ForgotPassword: React.FC = () => {
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const sendResetPasswordLink = async (values: any) => {
    try {
      setLoading(true);

      await EmailService.sendResetPasswordLink(values.email);

      setLoading(false);
      AlertUtil.createSuccessAlert(Utils.getTranslation(language, translator.pages.forgotPassword.emailSent), dispatch);
    } catch (e: any) {
      setLoading(false);
      console.error(e);
      AlertUtil.createErrorAlert(Utils.getTranslation(language, translator.errorMessages.general.unknown), dispatch);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          maxWidth: '500px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Box
          sx={{
            padding: 3,
            pt: 6,
            background: 'white',
            position: 'relative',
            width: '100%',
          }}>
          <Box sx={{
            position: 'absolute', width: '100px', right: 24, top: 24,
          }}>
            <LanguageSelector />
          </Box>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={yup.object({
              email: yup.string()
                .email(Utils.getTranslation(language, translator.formMessages.invalidEmail))
                .required(Utils.getTranslation(language, translator.formMessages.requiredField)),
            })}
            onSubmit={(values, { setSubmitting }) => {
              sendResetPasswordLink(values);
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: '500px',
                  width: '100%',
                }}
              >
                <Avatar sx={{ m: '0 auto 15px auto', bgcolor: 'secondary.main', color: 'info.main' }}>
                  <LockOutlinedIcon style={{ color: '#fff' }} />
                </Avatar>
                <Typography variant="h5">
                  {Utils.getTranslation(language, translator.pages.forgotPassword.title)}
                </Typography>
                <Form style={{ width: '100%' }}>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    color='primary'
                    label={Utils.getTranslation(language, translator.pages.forgotPassword.email)}
                    margin='normal'
                    fullWidth
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={formikProps.isSubmitting}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {Utils.getTranslation(language, translator.pages.forgotPassword.submit)}
                  </Button>
                </Form>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    width: '100%',
                  }}
                >
                  <Link to={searchParams.get('lang')
                    ? `/login?lang=${searchParams.get('lang')}`
                    : `/login?lang=${SupportedLanguages.DEFAULT}`}
                  style={{ color: 'black', fontSize: 14 }}>
                    {Utils.getTranslation(language, translator.pages.forgotPassword.toLogin)}
                  </Link>
                </Box>
              </Box>
            )}
          </Formik>
          <Spinner show={loading}/>
          <AlertsContainer/>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
