import {
  Box, Typography,
} from '@mui/material';
import {
  DataGrid, frFR, GridColDef, GridToolbar,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {
  collection, getDocs, orderBy, query,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import Layout from '../components/layout/Layout';
import Spinner from '../components/Spinner';
import { db } from '../FirebaseConfig';
import PricingPlan from '../models/enums/PricingPlans';
import { useAppSelector } from '../store/Hooks';

const SuperAdminPage: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  const [rows, setRows] = useState([]) as any[];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nom',
      width: 400,
    },
    {
      field: 'email',
      headerName: 'Courriel',
      width: 400,
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      width: 400,
    },
    {
      field: 'createdDate',
      headerName: 'Date de création',
      width: 200,
      renderCell: (params) => (
        <Typography sx={{
          fontSize: '1.1em',
        }}>
          {dayjs(params.row.createdDate).format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      field: 'clientsCounter',
      headerName: 'Nombre de clients',
      width: 200,
    },
    {
      field: 'plan',
      headerName: 'Plan',
      width: 200,
    },
    {
      field: 'ref',
      headerName: 'Ref',
      width: 200,
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 400,
    },
  ];

  useEffect(() => {
    async function fetchUsers() {
      const querySnapshot = await getDocs(query(collection(db, 'Users'), orderBy('createdDate', 'desc')));
      const newRows = [] as any;
      querySnapshot.forEach((doc) => {
        if (!doc.data().isSuperAdmin && !(doc.data().visibility === 'hidden')) {
          const {
            id, name, email, phone, documentsCounter, createdDate, plan,
          } = doc.data();
          newRows.push({
            id,
            name,
            email,
            phone,
            createdDate: createdDate.toDate(),
            clientsCounter: documentsCounter ? documentsCounter.clientsCounter : 0,
            plan: (plan === PricingPlan.PREMIUM) ? 'Pro' : 'Gratuit',
            ref: doc.data().ref ? doc.data().ref : '',
          });
        }
      });
      setRows(newRows);
    }

    fetchUsers();
  }, []);

  return (
    <Layout title="Mes Outils">
      {user && user.isSuperAdmin
        && <Box sx={{
          minHeight: 'calc(100vh - 64px)', width: '100%', padding: '32px 12px 12px', '@media (max-width:565px)': { padding: '8px 0 0' }, background: 'white',
        }}>
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              rowsPerPageOptions={[25, 50, 100]}
              experimentalFeatures={{ newEditingApi: true }}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  fontSize: '1.2em',
                },
                background: 'white',
              }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  printOptions: { disableToolbarButton: true },
                },
              }}
            />
          </Box>
        </Box>}
      <Spinner show={false}/>
    </Layout>
  );
};

export default SuperAdminPage;
