import {
  Box,
} from '@mui/material';
import { Formik } from 'formik';

import * as yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from '../../../store/Hooks';
import AlertUtils from '../../../utils/AlertUtil';
import Spinner from '../../Spinner';
import PlansRestrictions from '../../../models/enums/PlansRestrictions';
import ErrorCauses from '../../../models/enums/ErrorCauses';
import SuiviDateInput from '../../SuiviDateInput';
import ClientService from '../../../services/ClientService';
import Utils from '../../../utils/Utils';
import SupportedLanguages from '../../../models/enums/SupportedLanguages';
import translator from '../../../theme/translator.json';

const DraftSuiviAdd: React.FC<{clientId:string, nextSuivi?:Timestamp|null}> = ({ clientId, nextSuivi }) => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const formikRef = useRef() as any;
  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const validationSchema = yup.object({
    client: yup
      .string().required(Utils.getTranslation(language, translator.formMessages.requiredField)),
  });

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm({ values: generateInitialValues() });
    }
  }, [clientId, nextSuivi]);

  const generateInitialValues = () : {client:string, date: string, customDate:any} => {
    let date = nextSuivi ? 'custom' : '';
    const customDate = nextSuivi ? nextSuivi.seconds * 1000 : dayjs().unix() * 1000;
    if (nextSuivi) {
      const relativeOptions = Utils.generateRelativeDateOptions(language);
      const monthlyOptions = Utils.generateMonthlyDateOptions(language);

      relativeOptions.forEach((option:any) => {
        if (option.value === nextSuivi.seconds * 1000) date = option.value;
      });

      monthlyOptions.forEach((option:any) => {
        if (option.value === nextSuivi.seconds * 1000) date = option.value;
      });
    }

    return {
      client: clientId,
      date,
      customDate,
    };
  };

  return (
    <>
      <Box>
        <Formik
          innerRef={formikRef}
          initialValues={generateInitialValues()}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              setLoading(true);
              let newSuiviDate = null;
              if (values.date) newSuiviDate = (values.date === 'custom') ? Timestamp.fromDate(new Date(values.customDate)) : Timestamp.fromDate(new Date(values.date));
              await ClientService.updateNextSuiviDate(user.id, values.client, newSuiviDate);

              setLoading(false);
              AlertUtils.createSuccessAlert(Utils.getTranslation(language, translator.successMessages.suiviDateModified), dispatch);
            } catch (e) {
              setLoading(false);
              if (e instanceof Error) {
                if (e.cause === ErrorCauses.INSUFFICIENT_PLAN) {
                  AlertUtils.createErrorAlert(e.message, dispatch);
                }
                if (e instanceof FirebaseError) {
                  if (e.code === 'permission-denied') {
                    const message = Utils.getTranslation(language, translator.errorMessages.plan.freeTierSuivisLimited, {
                      numberSuivis: PlansRestrictions.FREEMIUM.suivisCount,
                    });
                    AlertUtils.createErrorAlert(message, dispatch);
                  }
                }
              } else {
                throw e; // re-throw the error unchanged
              }
            }
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <SuiviDateInput autoSave showNull />
            </form>
          )}
        </Formik>
      </Box>
      <Spinner show={loading}/>
    </>
  );
};

export default DraftSuiviAdd;
