import {
  Box, FormControl, MenuItem, Select, TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import Utils from '../utils/Utils';
import { useAppSelector } from '../store/Hooks';
import SupportedLanguages from '../models/enums/SupportedLanguages';
import translator from '../theme/translator.json';

type Props = {
  small?:boolean,
  autoSave?:boolean,
  showNull?:boolean,
};

const SuiviDateInput: React.FC<Props> = ({ small, autoSave, showNull }) => {
  const formik = useFormikContext() as any;

  const language = useAppSelector((state) => state.user.language) as SupportedLanguages;

  const [relativeDateOptions, setRelativeDateOptions] = useState() as any;
  const [monthlyDateOptions, setMonthlyDateOptions] = useState() as any;

  useEffect(() => {
    setRelativeDateOptions(Utils.generateRelativeDateOptions(language));
    setMonthlyDateOptions(Utils.generateMonthlyDateOptions(language));
  }, []);

  return (
    <>
      <FormControl sx={{ minWidth: '180px' }}>
        <Select
          value={formik.values.date ? formik.values.date : ''}
          name='date'
          onChange={(event) => {
            formik.setFieldValue('date', (event.target.value === 'custom') ? event.target.value : parseInt(event.target.value, 10), false);
            if (autoSave) formik.handleSubmit();
          }}
          size="small"
          displayEmpty
        >
          {showNull
          && <MenuItem value="">{Utils.getTranslation(language, translator.components.suiviDateInput.aucunSuivi)}</MenuItem>}
          {relativeDateOptions && relativeDateOptions.map((option : any) => (
            <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
          ))}
          {monthlyDateOptions && monthlyDateOptions.map((option : any) => (
            <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
          ))}
          <MenuItem value="custom">{Utils.getTranslation(language, translator.formLabels.personalisedDate)}</MenuItem>
        </Select>
      </FormControl>
      {formik.values.date === 'custom' && <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        pt: 2,
      }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={Utils.getTranslation(language, translator.formLabels.personalisedDate)}
            inputFormat="DD/MM/YYYY"
            value={dayjs.unix(formik.values.customDate / 1000)}
            onChange={(value) => {
              formik.setFieldValue('customDate', (value) ? value.hour(23).unix() * 1000 : dayjs().hour(23).unix() * 1000, false);
              if (autoSave) formik.handleSubmit();
            }}
            renderInput={(params) => <TextField
              name="customDate" {...params} />}
          />
        </LocalizationProvider>
      </Box>}
    </>
  );
};

export default SuiviDateInput;
